import { Deserializable } from "../deserializable.model";

export class BrochureModel implements Deserializable{

    private id:string
	private name:string
    private directory_name:string
    private position_x:string
    private position_y:string
    private is_downloadable:boolean
    private image_url:string
    private file_urls:string[]
    private children: BrochureModel[]
    private description:string
    private files:BrochureFilesModel[]
    
	deserialize(input: any) {
		Object.assign(this, input);
        if(input.events){
			this.children = input.children.map((itemlist: BrochureModel) => new BrochureModel().deserialize(itemlist))
		}else{
			this.children = []
		}

        if(input.files){
            this.files = input.files.map((list:BrochureFilesModel) => new BrochureFilesModel().deserialize(list))
        }else{
            this.files = []
        }

		return this;
	}

    getName() { return this.name }

    getDescription() { return this.description }

    getImageUrl(){
        return this.image_url
    }

    getFileUrl(){
        if (this.file_urls.length > 0) return this.file_urls[0]
        else return ''
    }

    getFiles(){
        return this.files
    }

    isDownloadable(){ return this.isDownloadable }
}

export class BrochureFilesModel implements Deserializable{

    private id:string
	private name:string
    private download_url:string
    private file_url:string
    private is_saved:boolean
    private file_type:string
    private thumbnail_url:string

    
	deserialize(input: any) {
		Object.assign(this, input);
        
		return this;
	}

    getName() { return this.name }

    getID() { return this.id }

    getFileUrl(){
        return this.file_url
    }

    getDownloadUrl(){
        return this.download_url
    }

    isSaved(){
        return this.is_saved
    }

    setSaved(value:boolean){
        this.is_saved = value
    }

    getFileType(){
        return this.file_type
    }

    getFileTypeIcon(){

        let result = ''

        if(this.file_type == 'attachment'){
            result = 'attach'
        }

        if(this.file_type == 'video'){
            result = 'videocam'
        }

        if(this.file_type == 'image'){
            result = 'image'
        }

        return result

    }

    getThumbnail(){
		return this.thumbnail_url
	}

}


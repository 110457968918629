import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { AuthService } from './auth.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { LanguageService } from './language.service';
import { switchMap } from 'rxjs/operators';
@Injectable({
  providedIn: 'root'
})
export class ConnectionsService {
  language:any={};
  constructor(
    private http: HttpClient,
    private authService: AuthService,
    private languageService: LanguageService
  ) { 
    this.language = this.languageService.language();
  }
  addConnect(id,message) {
    let body = new FormData();
    body.append('message',message);
    return this.authService.token.pipe(
      switchMap(token => {
        const httpOptions = {
          headers: new HttpHeaders({
            'Authorization': `Bearer ${token}`,
            'X-Localization': `${this.language}`
          })
        };
        return this.http.post<any>(  //  `${environment.host}/contents?key=${content}`
          `${environment.host}/users/networks/${id}/connect`,   body,
          httpOptions
        );
      })
    );
  }
  approveConnect(id) {
    let body = new FormData();
    return this.authService.token.pipe(
      switchMap(token => {
        const httpOptions = {
          headers: new HttpHeaders({
            'Authorization': `Bearer ${token}`,
            'X-Localization': `${this.language}`
          })
        };
        return this.http.post<any>(  //  `${environment.host}/contents?key=${content}`
          `${environment.host}/users/networks/${id}/approved`,   body,
          httpOptions
        );
      })
    );
  }
  rejectConnect(id) {
    let body = new FormData();
    return this.authService.token.pipe(
      switchMap(token => {
        const httpOptions = {
          headers: new HttpHeaders({
            'Authorization': `Bearer ${token}`,
            'X-Localization': `${this.language}`
          })
        };
        return this.http.post<any>(  //  `${environment.host}/contents?key=${content}`
          `${environment.host}/users/networks/${id}/rejected`,   body,
          httpOptions
        );
      })
    );
  }
  
  getList(page = 1,search='') {
    return this.authService.token.pipe(
      switchMap(token => {
        const httpOptions = {
          headers: new HttpHeaders({
            'Authorization': `Bearer ${token}`,
            'X-Localization': `${this.language}`
          })
        };
        return this.http.get<any>(  //  `${environment.host}/contents?key=${content}`
          `${environment.host}/users/networks?page=${page}&search=${search}`,
          httpOptions
        );
      })
    );
  }

  getListID() {
    return this.authService.token.pipe(
      switchMap(token => {
        const httpOptions = {
          headers: new HttpHeaders({
            'Authorization': `Bearer ${token}`,
            'X-Localization': `${this.language}`
          })
        };
        return this.http.get<any>(  //  `${environment.host}/contents?key=${content}`
          `${environment.host}/users/networkIds`,
          httpOptions
        );
      })
    );
  }

  
}

import { Component, OnInit } from '@angular/core';
import { LoadingController, ModalController } from '@ionic/angular';
import { GeneralService } from 'src/app/service/general.service';

@Component({
  selector: 'app-privacy-policy-event',
  templateUrl: './privacy-policy-event.page.html',
  styleUrls: ['./privacy-policy-event.page.scss'],
})
export class PrivacyPolicyEventPage implements OnInit {

  content:any = ""

  constructor(
    private modalController: ModalController,
    private loadingCtrl: LoadingController,
    private generalService: GeneralService,
  ) { }

  ngOnInit() {

    this.getContent()

  }

  getContent(){

    this.loadingCtrl.create({ keyboardClose: true }).then(
      loading => {
      
        loading.present()

        this.generalService.getSettingsContent('privacy_policy').subscribe(
          res => {

            loading.dismiss()
            this.content = res.data

          },
          err => {
    
            loading.dismiss()

          }
        )
      
      }
    )

  }

  closeModal() {
    this.modalController.dismiss();
  }

}

import { Component, HostListener, ViewChild } from '@angular/core';
import { Platform } from '@ionic/angular';
import { AuthService } from './service/auth.service';
import {Echo} from 'laravel-echo-ionic';
import { environment } from 'src/environments/environment';
import { User } from './models/user.model';
import { NotificationComponent } from './components/notification/notification.component';
import { NavigationStart, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
//firebase
import firebase from 'firebase/app'
import 'firebase/database';
import { EventService } from './service/event.service';
import { GeneralService } from './service/general.service';
export const myFirebase = firebase.initializeApp(environment.firebase, 'appComponentFirebase');
export const listenRoomPrivate = '/kelolain/users/';
export const listenRoomBlast = '/kelolain/blasts/';
//firebase

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  
  laravelEchoPrivate:any;
  user:User

  notifFlag:any = 0
  notifTitle:any = ''
  notifDescription:any = ''
  notifClickAction:any = ''

  notifChange:boolean = false
  
  @ViewChild (NotificationComponent)
  private notifComponent: NotificationComponent

  disabledUrl:any = environment.disableRigthClick
  isMobile:boolean = false

  firebasePrivate:any
  firebaseBlast:any
  firebaseMessage:any

  keyPrefix:any=environment.keyPrefix;

  constructor(
    private platform: Platform,
    private authService: AuthService,
    private router: Router,
    private eventService: EventService,
    private generalService: GeneralService
  ) {
    this.initializeApp();
  }

  initializeApp() {
    this.platform.ready().then(() => {
      
      var dateNow = new Date().getTime();;

      this.firebaseBlast = myFirebase.database().ref(listenRoomBlast);
      this.firebaseBlast.on('child_added', (snapshot) => {

        if(snapshot.val().timestamp){
          
          dateNow =  Math.floor(dateNow);
          let timesaatIni = new Date(snapshot.val().timestamp).getTime();
          
          if( timesaatIni  >= dateNow ){
            this.notifComponent.showNotif(snapshot.val())
          }
        }
      
      });
      
    });

    localStorage.setItem('bgAudio', "1");

    this.router.events
    .pipe(filter(event => event instanceof NavigationStart))
    .subscribe((event: NavigationStart) => {
      // console.log('prev:', event.url);

      this.authService.userIsAuthenticated.subscribe(
        data => {
          if(data){
  
            this.authService.user.subscribe(user => {

              this.user = new User().deserialize(user) ;

              if(this.user){

                if(!this.firebasePrivate){

                  var dateNow = new Date().getTime();;

                  this.firebasePrivate = myFirebase.database().ref(listenRoomPrivate+this.user.getID());
                  this.firebasePrivate.on('child_added', (snapshot) => {

                    if(snapshot.val().timestamp){
                      
                      dateNow =  Math.floor(dateNow);
                      let timesaatIni = new Date(snapshot.val().timestamp).getTime();

                      if( timesaatIni  >= dateNow ){
                        this.notifComponent.showNotif(snapshot.val())
                        this.eventService.setNotifFlag()
                      }
                    }
                  
                  });
        
                }

                if(true){

                  var commentsRef = myFirebase.database().ref(this.keyPrefix+'/personal_chat/'+this.user.id);
                  commentsRef.on('value', (snapshot) => {

                    this.eventService.setFlagMessage()
                    
                  });

                }
        
              }

            });
  
          }
        }
      )

    });

  }

  ionViewDidEnter() {
    
    // console.log("called")

  }

  @HostListener('document:visibilitychange', ['$event'])
  visibilitychange() {
    if (document.hidden){
      this.generalService.pauseAudioBG();
      } else {
        if(this.generalService.checkAudioBgFLag() == "1"){
          if(!this.router.url.includes("session") && !this.router.url.includes("booth")){
            this.generalService.playAudioBG();
          }
        }
    }
  }

}

import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Subject, Observable, from  } from 'rxjs';
import { map, switchMap, take, tap } from 'rxjs/operators';
import { LanguageService } from './language.service';
import { formatDate } from '@angular/common';
import { Plugins } from '@capacitor/core';
import { AlertController } from '@ionic/angular';
import { ActivatedRoute, NavigationExtras } from "@angular/router";
import { NavController } from "@ionic/angular";
import { AuthService } from './auth.service';
import * as crypto from 'crypto-js';
import aes from 'crypto-js/aes'
import encHex from 'crypto-js/enc-hex'
import padZeroPadding from 'crypto-js/pad-zeropadding'
@Injectable({
  providedIn: 'root'
})
export class ChatService {

  language;

  constructor(
    private alertCtrl: AlertController,
    private http: HttpClient,
    private languageService: LanguageService,
    private navCtrl:NavController,
    private authService:AuthService,
  ) { 

    this.language = this.languageService.language();

  }
  getPrivateRoom(email,email_to,from_name,to_name, type, exhibitor_id, to_agency, from_agency, to_id, from_id){
    return this.authService.token.pipe(
      take(1),
      switchMap(token => {
        let body = new FormData();
        body.append('email_from', email);
        body.append('client_id',environment.chatClient);
        body.append('email_to', email_to);
        body.append('from_name', from_name);
        body.append('from_agency', from_agency);
        body.append('from_id', from_id);
        body.append('to_name', to_name);
        body.append('to_agency', to_agency);
        body.append('to_id', to_id);
        body.append('type', type);
        body.append('exhibitor_id', exhibitor_id)
        const httpOptions = {
          headers: new HttpHeaders({
            'Authorization': `Bearer ${token}`
          })
        };
        return this.http.post<any>(
          `${environment.chatAPI}/api/private-room`,
          body,
          httpOptions
        );
      })
    );
  }
  getRoomList(email,page, keyword,user_id){
    let data = {
      'email': email,
      'client_id': environment.chatClient,
      'user_id':user_id
    }

    // the key and iv should be 32 hex digits each, any hex digits you want, but it needs to be 32 on length each
    let key = encHex.parse(environment.encryptKey);
    let iv =  encHex.parse(environment.encryptIV);

    // encrypt the message
    let encrypted = aes.encrypt(JSON.stringify(data), key, {iv:iv, padding:padZeroPadding}).toString();


    return this.authService.token.pipe(
      take(1),
      switchMap(token => {
        let body = new FormData();
        body.append('data', encrypted);
        body.append('client_id',environment.chatClient);
        const httpOptions = {
          headers: new HttpHeaders({
            'Authorization': `Bearer ${token}`
          })
        };
        return this.http.post<any>(
          `${environment.chatAPI}/api/personal-history?page=${page}&keyword=${keyword}`,
          body,
          httpOptions
        );
      })
    );
  }
  sendNotifChat(exhibitorId,email,from_name){
    return this.authService.token.pipe(
      take(1),
      switchMap(token => {
        let body = new FormData();
        body.append('email', email);
        body.append('client_id',environment.chatClient);
        body.append('exhibitorId',exhibitorId);
        body.append('from_name',from_name);
        const httpOptions = {
          headers: new HttpHeaders({
            'Authorization': `Bearer ${token}`
          })
        };
        return this.http.post<any>(
          `${environment.chatAPI}/api/livechat-exhibitor-request`,
          body,
          httpOptions
        );
      })
    );
  }
  
  unrequestVideoExhibitor(data,exhibitorId, type){
    return this.authService.token.pipe(
      take(1),
      switchMap(token => {
        let body = new FormData();
        body.append('data', JSON.stringify(data));
        body.append('email', data.email);
        body.append('client_id',environment.chatClient);
        body.append('exhibitor_id', exhibitorId);
        body.append('type', type);
        const httpOptions = {
          headers: new HttpHeaders({
            'Authorization': `Bearer ${token}`
          })
        };
        return this.http.post<any>(
          `${environment.chatAPI}/api/unrequest-video`,
          body,
          httpOptions
        );
      })
    );
  }

  endedVideoExhibitor(roomCode){
    return this.authService.token.pipe(
      take(1),
      switchMap(token => {
        let body = new FormData();
        body.append('roomCode',roomCode);
        body.append('client_id',environment.chatClient);
        const httpOptions = {
          headers: new HttpHeaders({
            'Authorization': `Bearer ${token}`
          })
        };
        return this.http.post<any>(
          `${environment.chatAPI}/api/end-video`,
          body,
          httpOptions
        );
      })
    );
  }
  req

  requestVideoExhibitor(data,exhibitorId, type){
    return this.authService.token.pipe(
      take(1),
      switchMap(token => {
        let body = new FormData();
        body.append('data', JSON.stringify(data));
        body.append('email', data.email);
        body.append('client_id',environment.chatClient);
        body.append('exhibitor_id', exhibitorId);
        body.append('type', type);
        const httpOptions = {
          headers: new HttpHeaders({
            'Authorization': `Bearer ${token}`
          })
        };
        return this.http.post<any>(
          `${environment.chatAPI}/api/request-video`,
          body,
          httpOptions
        );
      })
    );
  }

  sendChat(room_code,email_from,message,email_name,type,attachment){
    return this.authService.token.pipe(
      take(1),
      switchMap(token => {

        let data = {
          'room_code': room_code,
          'email_from': email_from,
          'message': message,
          'email_name': email_name,
          'type': type 
        }

        // the key and iv should be 32 hex digits each, any hex digits you want, but it needs to be 32 on length each
        let key = encHex.parse(environment.encryptKey);
        let iv =  encHex.parse(environment.encryptIV);

        // encrypt the message
        let encrypted = aes.encrypt(JSON.stringify(data), key, {iv:iv, padding:padZeroPadding}).toString();

        // console.log(key)
        // console.log(iv)


        let body = new FormData();
        // body.append('room_code', room_code);
        // body.append('email_from', email_from);
        // body.append('message', message);
        // body.append('email_name', email_name);
        // body.append('type', type);
        body.append('data', encrypted)
        body.append('attachment', attachment);
        const httpOptions = {
          headers: new HttpHeaders({
            'Authorization': `Bearer ${token}`
          })
        };
        return this.http.post<any>(
          `${environment.chatAPI}/api/send-private-chat`,
          body,
          httpOptions
        );

        
      })
    );
  }
  sendChatRequestFlag(userRoomName,isUserOpen,isAdminOpen,userId,memberCode,urlLink,type,roomCode,exhibitorId){
    return this.authService.token.pipe(
      take(1),
      switchMap(token => {
        
        let body = new FormData();
        body.append('userRoomName', userRoomName);
        body.append('isUserOpen', isUserOpen);
        body.append('isAdminOpen', isAdminOpen);
        body.append('userId', userId);
        body.append('memberCode', memberCode);
        body.append('urlLink', urlLink);
        body.append('type', type);
        body.append('roomCode', roomCode);
        body.append('client_id',environment.chatClient);
        body.append('exhibitor_id',exhibitorId);
        
        const httpOptions = {
          headers: new HttpHeaders({
            'Authorization': `Bearer ${token}`
          })
        };
        return this.http.post<any>(
          `${environment.chatAPI}/api/chat-request-flag`,
          body,
          httpOptions
        );
      })
    );
  }

  
  getChatList(room_code, email, user_id){
    return this.authService.token.pipe(
      take(1),
      switchMap(token => {


        let data = {
          'room_code': room_code,
          'email': email,
          'user_id': user_id
        }

        // the key and iv should be 32 hex digits each, any hex digits you want, but it needs to be 32 on length each
        let key = encHex.parse(environment.encryptKey);
        let iv =  encHex.parse(environment.encryptIV);

        // encrypt the message
        let encrypted = aes.encrypt(JSON.stringify(data), key, {iv:iv, padding:padZeroPadding}).toString();

        let body = new FormData();
        body.append('data', encrypted);
        // body.append('email', email);
        const httpOptions = {
          headers: new HttpHeaders({
            'Authorization': `Bearer ${token}`
          })
        };
        return this.http.post<any>(
          `${environment.chatAPI}/api/private-chat`,
          body,
          httpOptions
        );
      })
    );
  }

  getTotalUnread(clientID, email){
    return this.authService.token.pipe(
      take(1),
      switchMap(token => {
        let body = new FormData();
        body.append('client_id', clientID);
        body.append('email', email);
        const httpOptions = {
          headers: new HttpHeaders({
            'Authorization': `Bearer ${token}`
          })
        };
        return this.http.post<any>(
          `${environment.chatAPI}/api/totalunread`,
          body,
          httpOptions
        );
      })
    );
  }

  editProfile(name, email){

    return this.authService.token.pipe(
      take(1),
      switchMap(token => {

    //     console.log(name)
    // console.log(email)

        let body = new FormData();
        body.append('name', name);
        body.append('email_from', email);
        body.append('email_to', email);
        const httpOptions = {
          headers: new HttpHeaders({
            'Authorization': `Bearer ${token}`
          })
        };
        return this.http.post<any>(
          `${environment.chatAPI}/api/edit-profile`,
          body,
          httpOptions
        );
      })
    );
  }

}

import { Deserializable } from "../deserializable.model";
import { BrochureFilesModel } from "./brochure.model";
import { EventExhibitor } from "./exhibitor.model";
import { MaterialModel } from "./material.model";
import { EventParticipant } from "./participant.model";
import { SessionSchedule } from "./sessionSchedule.model";

export class EventDetailModel implements Deserializable {
	
	private id: number
	private name: number
	private description: string
	private category: string
	private interest: string
	private organizer: EventOrganizer
	private location:any
	private is_online: boolean
	private status: string
	private image_url: string
	private sessions: EventSession[]
	private galleries: EventGallery[]
	private started_at: string
	private ended_at:string
	private googlemapsurl:string = "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3966.6683326435145!2d106.7876613148747!3d-6.175137995529361!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e69f65f1f83abcf%3A0xa35fd45d6f0b119b!2sUnictive%20Media%20(PT%20UNIKTIF%20MEDIA%20INDONESIA)!5e0!3m2!1sen!2sid!4v1618225488461!5m2!1sen!2sid"
	private country:string = "Indonesia"
	private _location:string = "DKI Jakarta"
	private interest_users:EventMatchInterest[]
	private exhibitors:EventExhibitor[]
	private rooms:EventRoom[]
	private surveys:EventSurvey[]
	private participants_count:number
	private participants:EventParticipant[]
	private ticket_price_starting_from:number
	private is_started:boolean = false
	private lobby_url:string
	private lobby_type:string

	public constructor(
	) {}

	getId(){
		return this.id
	}

	getName(){
		return this.name
	}

	getGoogleMaps(){
		return this.googlemapsurl
	}

	getImageUrl(){
		return this.image_url
	}

	getDescription(){
		return this.description
	}

	getIsOnline(){
		return this.is_online
	}

	getLocation(){
		if(this.location){
			return this.location.formatted_address
		}else{
			return ""
		}
		
	}

	getPlaceID(){
		if(this.location){
			return this.location.place_id
		}else{
			return ""
		}
		
	}

	getOrganizer(){
		return this.organizer
	}

	getGalleries(){
		return this.galleries
	}

	getImagesGalleries(){
		
		let result:EventGallery[] = []
		
		for(let i = 0; i < this.galleries.length; i++){

			if(this.galleries[i].getType() == "image"){

				result.push(this.galleries[i])

			}

		}

		return result
	}

	getVideoGalleries(){
		
		let result:EventGallery[] = []
		
		for(let i = 0; i < this.galleries.length; i++){

			if(this.galleries[i].getType() != "image"){

				result.push(this.galleries[i])

			}

		}

		return result
	}

	getSession(){
		return this.sessions
	}
	
	setSession(sessions){

		this.sessions = sessions.map((sessionlist: EventSession) => new EventSession().deserialize(sessionlist))

	}

	setSessionRoom(result){

		let eventRoom = result.data.map((list:EventRoom) => new EventRoom().deserialize(list))

		eventRoom.forEach((room, index) => {

			let roomSEssion = room.sessions.map((list:SessionSchedule) => new SessionSchedule().deserialize(list))

			roomSEssion.forEach((schedules, index) => {

				schedules.getSchedule().forEach((session, index) => {
					this.sessions.push(session)
				})

			})
  
		  })

		// console.log(this.sessions)

	}

	getStartDate(){
		return this.started_at
	}

	getEndDate(){
		return this.ended_at
	}

	getCountry(){
		return this.country
	}

	get_Location(){
		return this._location
	}

	getLowestPrice(){

		return this.ticket_price_starting_from

	}

	getMatchInterest(){
		return this.interest_users
	}

	setMatchInterest(interest_users){

		this.interest_users = interest_users.map((interestUserList: EventMatchInterest) => new EventMatchInterest().deserialize(interestUserList))

	}

	getExhibitor(){
		return this.exhibitors
	}

	getRoom(){
		return this.rooms
	}

	setRoom(rooms){

		this.rooms = rooms.map((roomslist: EventRoom) => new EventRoom().deserialize(roomslist))

	}

	getSurvey(){
		return this.surveys
	}

	getParticipantCount(){
		return this.participants_count
	}

	getParticipant(){
		return this.participants
	}
	
	getIsStarted(){
		return this.is_started
	}

	setIsStarted(data){
		this.is_started = data
	}

	getLobbyBgUrl(){
		return this.lobby_url
	}

	getLobbyBgType(){
		return this.lobby_type
	}

	deserialize(input: any) {
		Object.assign(this, input);
		
		if(input.organizer){
			this.organizer = new EventOrganizer().deserialize(input.organizer);
		}else{
			this.organizer = new EventOrganizer().deserialize("{}");
		}
		
		if(input.galleries){
			this.galleries = input.galleries.map((gallerylist: EventGallery) => new EventGallery().deserialize(gallerylist))
		}else{
			this.galleries = []
		}

		if(input.sessions){
			this.sessions = input.sessions.map((sessionlist: EventSession) => new EventSession().deserialize(sessionlist))
		}else{
			this.sessions = []
		}

		if(input.interest_users){
			this.interest_users = input.interest_users.map((interestUserList: EventMatchInterest) => new EventMatchInterest().deserialize(interestUserList))
		}else{
			this.interest_users = []
		}

		if(input.exhibitors){
			this.exhibitors = input.exhibitors.map((exhibitorlist: EventExhibitor) => new EventExhibitor().deserialize(exhibitorlist))
		}else{
			this.exhibitors = []
		}

		if(input.rooms){
			this.rooms = input.rooms.map((roomslist: EventRoom) => new EventRoom().deserialize(roomslist))
		}else{
			this.rooms = []
		}

		if(input.surveys){
			this.surveys = input.surveys.map((surveylist: EventSurvey) => new EventSurvey().deserialize(surveylist))
		}else{
			this.surveys = []
		}

		if(input.participants){
			this.participants = input.participants.map((participantist: EventParticipant) => new EventParticipant().deserialize(participantist))
		}else{
			this.participants = []
		}
		
		return this;
	}

}

export interface EventHasImage{
  event_has_image_id: number,
  event_has_image: string
}

export class EventListModel implements Deserializable {

	public id: number
	public slug: string
	public name: string
	public category: string
	public interest: string
	public location: string
	public is_online: boolean
	public status: string
	public image_url: string
	public started_at: string
	public ended_at: string
	public eventOrganizer:EventOrganizer
	public country: string = "Indonesia"
	public _location: string = "DKI Jakarta"

	public constructor(
		
	) {}

	getEventID(){
		return this.id
	}

	getEventName(){
		return this.name
	}

	getStartDate(){
		return this.started_at
	}

	getEndDate(){
		return this.ended_at
	}

	getLocation(){
		return this.location
	}

	getEventOrganizer(){
		return this.eventOrganizer
	}

	getImageUrl(){
		return this.image_url
	}

	getEventSlug(){
		return this.slug
	}

	getCountry(){
		return this.country
	}

	get_location(){
		return this._location
	}

	getIsOnline(){
		return this.is_online
	}

	deserialize(input: any) {
		Object.assign(this, input);
		this.eventOrganizer = new EventOrganizer().deserialize(input.organizer)
		return this;
	}

}

export class EventGallery implements Deserializable{


	public id:string
	public name:string
	public order:number
	public type:string
	public file_url:string

	getId(){
		return this.id
	}

	getName(){
		return this.name
	}

	getOrder(){
		return this.order
	}

	getType(){
		return this.type
	}

	getFileUrl(){
		return this.file_url
	}

	deserialize(input: any) {
		Object.assign(this, input);
		return this;
	}

}

export class EventOrganizer implements Deserializable{

	private name:string
	private photo_url:string
	private email:string
	private phone:string 
	private external_link:string

	deserialize(input: any) {
		Object.assign(this, input);
		return this;
	}

	getName(){

		return this.name

	}

	getPhotoUrl(){

		return this.photo_url

	}

	getEmail(){
		return this.email
	}

	getPhone(){
		return this.phone
	}

	getZoomMeetingSetting(){

		let data = {
			"external_link": this.external_link
		}

		return data

	}

}

export class EventSession implements Deserializable{

	private id:string
	private name:string
	private description:string
	private speaker:EventSpeaker[]
	private image_url:string
	private started_at:string
	private ended_at:string
	private opened_at:string
	private stream_type:string
	private stream_resource:EventStreamResource
	private period_time_formatted:string
	private is_opened:boolean
	private is_started:boolean
	private is_video_replay_on:boolean
	private is_video_on:boolean
	private materials:MaterialFilesModel[]
	private video_url:any
	private is_ended:boolean
	private thumbnail_material_url:string
	private logo_url:string
	private rooms:any = {}

	deserialize(input: any) {
		Object.assign(this, input);
		if(input.speakers){
			this.speaker = input.speakers.map((speakerlist: EventSpeaker) => new EventSpeaker().deserialize(speakerlist))
		}else{
			this.speaker = []
		}

		if(input.materials){
			this.materials = input.materials.map((list:MaterialFilesModel) => new MaterialFilesModel().deserialize(list))
		}else{
			this.materials = []
		}

		if(input.rooms){
			this.rooms = input.rooms[0]
		}
		
		this.stream_resource = new EventStreamResource().deserialize(input.stream_resource)
		return this;
	}

	getID(){
		return this.id
	}

	getName(){

		return this.name

	}

	getImageUrl(){

		return this.image_url

	}

	getDescription(){

		if(this.description == '<p></p>' || this.description == '<p><br></p>'){
			this.description = null
		}

		return this.description
	}

	getSpeaker(){
		return this.speaker
	}

	getStartAt(){
		return this.started_at
	}

	getEndAt(){
		return this.ended_at
	}

	getOpenendAt(){
		return this.opened_at
	}

	getStreamType(){
		return this.stream_type
	}

	getStreamResource(){
		return this.stream_resource
	}

	setStreamResource(stream){
		this.stream_resource = stream 
	}

	isCanJoin(){

		let start:Date = new Date(this.opened_at)
    	let end:Date = new Date(this.ended_at)
		let now:Date = new Date()

		if(now > start && now < end){
			return true
		}else{
			return false
		}
	
	}

	isOpened(){
		return this.is_opened
	}

	isStarted(){
		return this.is_started
	}

	isEnded(){
		return this.is_ended
	}

	isVideoReply(){
		return this.is_video_replay_on
	}

	isVideoOn(){
		return this.is_video_on
	}

	getVideoUrl(){
		return this.video_url
	}

	getPeriodTime(){
		return this.period_time_formatted
	}

	getMaterials(){
		return this.materials
	}

	getThumbnailMaterial(){
		return this.thumbnail_material_url
	}

	getLogoUrl(){
		return this.logo_url
	}

	getRoomId(){
		return this.rooms.id
	}

	getZoomUrl(){
		return `https://zoom.us/j/${this.getStreamResource().getRooms()[0].id}?pwd=${this.getStreamResource().getRooms()[0].password}`
	}

}

export class MaterialFilesModel implements Deserializable{

    private id:string
	private name:string
    private download_url:string
    private file_url:string
    private is_saved:boolean
    private file_type:string
	private thumbnail_url:string

    
	deserialize(input: any) {
		Object.assign(this, input);
        
		return this;
	}

    getName() { return this.name }

    getID() { return this.id }

    getFileUrl(){
        return this.file_url
    }

    getDownloadUrl(){
        return this.download_url
    }

    isSaved(){
        return this.is_saved
    }

    setSaved(value:boolean){
        this.is_saved = value
    }

    getFileType(){
        return this.file_type
    }

    getFileTypeIcon(){

        let result = ''

        if(this.file_type == 'attachment'){
            result = 'attach'
        }

        if(this.file_type == 'video'){
            result = 'videocam'
        }

        if(this.file_type == 'image'){
            result = 'image'
        }

        return result

    }

	getThumbnail(){
		return this.thumbnail_url
	}

}

export class EventStreamResource implements Deserializable{

	private url:string

	private rooms:any = []
	private livechat:boolean
	private qna:boolean
	private chat_room_id:any

	deserialize(input: any) {
		Object.assign(this, input);
		return this;
	}

	getUrl(){
		let urlResource :any = this.url;
		console.log(urlResource);
		if(urlResource.HD){
			return encodeURIComponent(JSON.stringify(urlResource));
		}else{
			return this.url
		}
	

	}

	getRooms(){
		return this.rooms
	}

	getLiveChat(){
		return this.livechat
	}

	getQNA(){
		return this.qna
	}

	getChatRoomID(){
		return this.chat_room_id
	}

}

export class EventSpeaker implements Deserializable {
	
	private id: number
	private name: string
	private position: string
	private description: string
	private company: string
	private image_url: string
	private photo_url: string
	private linkedin:string = "linkedin"
	private instagram:string = "instagram"
	private sessions:EventSession[]
	private is_show_as_main:boolean

	
	deserialize(input: any) {
		Object.assign(this, input);
		
		if(input.sessions){
			this.sessions = input.sessions.map((list:EventSession) => new EventSession().deserialize(list))
		}else{
			this.sessions = []
		}

		return this;
	}

	getId(){
		return this.id
	}

	getName(){
		return this.name
	}

	getInitial(){

		let initial = ""
		
		if(this.name){
			let member_name = this.name.split(" ");
			if (member_name.length > 1) {
				initial = member_name[0].charAt(0).toUpperCase() + member_name[1].charAt(0).toUpperCase();
			} else {
				initial = member_name[0].charAt(0).toUpperCase();
			}
		}

		return initial

	}

	getImageURL(){
		if(this.image_url){
			return this.image_url
		}

		if(this.photo_url){
			return this.photo_url
		}
	}

	getDescription(){
		return this.description
	}

	getPosition(){
		return this.position
	}

	getCompany(){
		return this.company
	}

	getLinkedin(){
		return this.linkedin
	}

	getInstagram(){
		return this.instagram
	}

	getSession(){
		return this.sessions
	}

	isShowMain(){
		return this.is_show_as_main
	}

	public constructor() {}
}

export class EventMatchInterest implements Deserializable {
	
	public id: string
	public name: string
	public email: string
	public phone: string
	public company_name: string
	public company_position: string
	public photo_url: string

	
	deserialize(input: any) {
		Object.assign(this, input);
		return this;
	}

	getId(){
		return this.id
	}

	getName(){
		return this.name
	}

	getPhotoUrl(){
		return this.photo_url
	}

	getEmail(){
		return this.email
	}

	getPhone(){
		return this.phone
	}

	getPosition(){
		return this.company_position
	}

	getCompany(){
		return this.company_name
	}

	getInitial(){

		let initial = ""
		let member_name = this.name.split(" ");
		if (member_name.length > 1) {
			initial = member_name[0].charAt(0).toUpperCase() + member_name[1].charAt(0).toUpperCase();
		} else {
			initial = member_name[0].charAt(0).toUpperCase();
		}

		return initial

	}

	public constructor() {}
}

export class EventRoom implements Deserializable {
	
	private id: number
	private name: string
	private description: string
	private sessions:any

	
	deserialize(input: any) {
		Object.assign(this, input);
		// if(input.sessions){
		// 	this.sessions = input.sessions.map((sessionlist:EventSession) => new EventSession().deserialize(sessionlist))
		// }else{
		// 	this.sessions = []
		// }
		return this;
	}

	getId(){
		return this.id
	}

	getName(){
		return this.name
	}

	getDescription(){
		return this.description
	}

	getSession(){
		return this.sessions
	}

	public constructor() {}
}

export class EventSurvey implements Deserializable{

	private id:string
	private description:string
	private name:string
	private icon:string = "/assets/images/icon-survey1.png"
	private questions:EventSurveyQuestion[]
	private is_answered:boolean;

	deserialize(input: any) {
		Object.assign(this, input);

		if(input.questions){
			this.questions = input.questions.map((questionlist:EventSurveyQuestion) => new EventSurveyQuestion().deserialize(questionlist))
		}else{
			this.questions = []
		}

		return this;
	}

	getID(){
		return this.id
	}

	getName(){
		return this.name
	}

	getDescription(){
		return this.description
	}

	getIconUrl(){
		return this.icon
	}

	getQuestion(){
		return this.questions
	}

	getIsAnswered(){
		return this.is_answered
	}

}

export class EventSurveyQuestion implements Deserializable{

	private id:string
	private offered_answers:any
	private text:string
	private type:string
	private rateRange:any = []

	deserialize(input: any) {
		Object.assign(this, input);
		return this;
	}

	getID(){
		return this.id
	}

	getText(){
		return this.text
	}

	getType(){
		return this.type
	}

	getOfferedAnswer(){
		return this.offered_answers
	}

	setRateRange(){

		let range:number = +this.offered_answers[0].range

		for(let i = 0; i < range; i++){

			this.rateRange.push(i + 1)

		}

	}

	getRateRange(){
		return this.rateRange
	}

}

import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { Plugins } from "@capacitor/core";
import { ModalController } from "@ionic/angular";
import { switchMap, take } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { ConfirmConnectionPage } from "../popup/confirm-connection/confirm-connection.page";
import { AuthService } from "./auth.service";
import { LanguageService } from "./language.service";

@Injectable({
providedIn: 'root'
})
export class MemberService {

    language;

    constructor(
        private http: HttpClient,
        private languageService: LanguageService,
        private authService: AuthService,
        private router: Router,
        private modalController: ModalController
    ) { 
      
        this.language = this.languageService.language();

    }

    getMemberProfile(){

        return this.authService.token.pipe(
          take(1),
          switchMap(token => {
            
            // console.log(token)
            
            const httpOptions = {
              headers: new HttpHeaders({
                'Authorization': `Bearer ${token}`
              })
            };
    
            return this.http.get<any>(
              `${environment.host}/users`,
              httpOptions
            );
          })
        );
    
    }

    editProfile(params, participant_type) {
        return this.authService.token.pipe(
          switchMap(token => {


            let phone = ''

            if(params.phone.charAt(0) == 0){
              phone = "62" + params.phone.substring(1)
            }else{
              phone = "62" + params.phone
            }

            let body = new FormData();
            body.append('name',params.name);
            body.append('email',params.email);
            body.append('phone',phone);
            if(params.agency){
              body.append('agency',params.agency);
            }
            body.append('participant_type', participant_type)
            

            const httpOptions = {
              headers: new HttpHeaders({
                'Authorization': `Bearer ${token}`
              })
            };
            return this.http.post<any>(
              `${environment.host}/users`,
              body,
              httpOptions
            );
          }),
          take(1)
        );
      }

      editEmailPhone(params) {
        return this.authService.token.pipe(
          switchMap(token => {
            let body = new FormData();
            body.append('email',params.email);
            body.append('phone',params.no_telp);
    
            const httpOptions = {
              headers: new HttpHeaders({
                'Authorization': `Bearer ${token}`
              })
            };
            return this.http.post<any>(
              `${environment.host}/users/contact`,
              body,
              httpOptions
            );
          }),
          take(1)
        );
      }

      verifyContact(activation_token: string, activation_code: string) {
        // let body = new FormData();
        // body.append('token',activation_code);
    
        return this.authService.token.pipe(
          switchMap(token => {
            const body = { token: activation_code };

            const httpOptions = {
              headers: new HttpHeaders({
                'Authorization': `Bearer ${token}`
              })
            };
        
            return this.http.put<any>(
              `${environment.host}/users/contact/${activation_token}`,
              body, httpOptions
            );
          }),
          take(1)
        );

        
      }

      resendVerifyContactOtp(activation_token: string) {
        let body = new FormData();
        // body.append('token',activation_code);
    
        return this.http.post<any>(
          `${environment.host}/users/contact/${activation_token}/resend`,
          body
        );

        
        
      }

      logout() {

        return this.authService.token.pipe(
          switchMap(token => {
            const httpOptions = {
              headers: new HttpHeaders({
                'Authorization': `Bearer ${token}`
              })
            };
    
            return this.http.delete<any>(
              `${environment.host}/auth/logout`,
              httpOptions
            )
          })
        );

        // return this.authService.token.subscribe(
        //   token => {

        //     console.log(token)
            
        //     const httpOptions = {
        //       headers: new HttpHeaders({
        //         'Authorization': `Bearer ${token}`
        //       })
        //     };
    
        //     return this.http.delete<any>(
        //       `${environment.host}/auth/logout`,
        //       httpOptions
        //     )

        //   }
        // );
    
        
      }

      changePassword(oldpass, newpass, confirmnewpass){

        return this.authService.token.pipe(
          switchMap(token => {
            
            let body = { 'oldpassword': oldpass, 'newpassword' : newpass,  'newpassword_confirmation' : confirmnewpass };

            const httpOptions = {
              headers: new HttpHeaders({
                'Authorization': `Bearer ${token}`
              })
            };
            return this.http.put<any>(
              `${environment.host}/users`,
              body,
              httpOptions
            );
          }),
          take(1)
        );


      }

      updateProfilePicture(name:string, image:any) {
        let body = new FormData();
        body.append('name', name);
        body.append('photo',image);
        return this.authService.token.pipe(
          switchMap(token => {
            const httpOptions = {
              headers: new HttpHeaders({
                'Authorization': `Bearer ${token}`,
                // 'X-Localization': `${this.language}`
              })
            };
            return this.http.post<any>(
              `${environment.host}/users`,body,
              httpOptions
            );
          })
        );
      }

      deleteProfilePicture(name:string) {
        let body = new FormData();
        body.append('name', name);
        body.append('photo', null);
        return this.authService.token.pipe(
          switchMap(token => {
            const httpOptions = {
              headers: new HttpHeaders({
                'Authorization': `Bearer ${token}`,
                // 'X-Localization': `${this.language}`
              })
            };
            return this.http.post<any>(
              `${environment.host}/users`,body,
              httpOptions
            );
          })
        );
      }

  getEventFollowed(status,page){

    return this.authService.token.pipe(
      switchMap(token => {
        const httpOptions = {
          headers: new HttpHeaders({
            'Authorization': `Bearer ${token}`,
            'X-Localization': `${this.language}`
          })
        };
        return this.http.get<any>(
          `${environment.host}/users/events/followed?status=${status}&limit=10&page=${page}`,
          httpOptions
        );
      })
    );

  }

  getMyTicket(page){

    return this.authService.token.pipe(
      switchMap(token => {
        const httpOptions = {
          headers: new HttpHeaders({
            'Authorization': `Bearer ${token}`,
            'X-Localization': `${this.language}`
          })
        };
        return this.http.get<any>(
          `${environment.host}/users/tickets?page=${page}`,
          httpOptions
        );
      })
    );

  }

  submitLike(model, id){

    return this.authService.token.pipe(
      switchMap(token => {
        const httpOptions = {
          headers: new HttpHeaders({
            'Authorization': `Bearer ${token}`,
            'X-Localization': `${this.language}`
          })
        };
        return this.http.post<any>(
          `${environment.host}/users/${model}/${id}/liked`,null,
          httpOptions
        );
      })
    );

  }

  getFiles(page){

    return this.authService.token.pipe(
      switchMap(token => {
        const httpOptions = {
          headers: new HttpHeaders({
            'Authorization': `Bearer ${token}`,
            'X-Localization': `${this.language}`
          })
        };
        return this.http.get<any>(
          `${environment.host}/users/files?page=${page}`,
          httpOptions
        );
      })
    );

  }

  saveFile(fileID){

    return this.authService.token.pipe(
      switchMap(token => {
        const httpOptions = {
          headers: new HttpHeaders({
            'Authorization': `Bearer ${token}`,
            'X-Localization': `${this.language}`
          })
        };
        return this.http.post<any>(
          `${environment.host}/users/files/${fileID}/saved`,null,
          httpOptions
        );
      })
    )

  }

  deleteFile(fileID){

    return this.authService.token.pipe(
      switchMap(token => {
        const httpOptions = {
          headers: new HttpHeaders({
            'Authorization': `Bearer ${token}`,
            'X-Localization': `${this.language}`
          })
        };
        return this.http.delete<any>(
          `${environment.host}/users/files/${fileID}/deleted`,
          httpOptions
        );
      })
    )

  }

  getFile(type, page, keyword){

    return this.authService.token.pipe(
      switchMap(token => {
        const httpOptions = {
          headers: new HttpHeaders({
            'Authorization': `Bearer ${token}`,
            'X-Localization': `${this.language}`
          })
        };
        return this.http.get<any>(
          `${environment.host}/users/files?type=${type}&page=${page}&search=${keyword}`,
          httpOptions
        );
      })
    )

  }

  getAddress(){

    return this.authService.token.pipe(
      switchMap(token => {
        const httpOptions = {
          headers: new HttpHeaders({
            'Authorization': `Bearer ${token}`,
            'X-Localization': `${this.language}`
          })
        };
        return this.http.get<any>(
          `${environment.host}/users/addresses`,
          httpOptions
        );
      })
    );

  }

  async connectFriends(data){
  
    const modal = await this.modalController.create(
        { 
          component: ConfirmConnectionPage,
          cssClass: "smallmodal",
          componentProps:{
            participant: data
          }
        }
      )
  
      return await modal;
  
  
  }

  deleteFriend(id){
    return this.authService.token.pipe(
      switchMap(token => {
        const httpOptions = {
          headers: new HttpHeaders({
            'Authorization': `Bearer ${token}`,
            'X-Localization': `${this.language}`
          })
        };
        return this.http.delete<any>(
          `${environment.host}/users/networks/${id}/deleted`,
          httpOptions
        );
      })
    );
  }

  getMyTicketType(){

    return this.authService.token.pipe(
      switchMap(token => {
        const httpOptions = {
          headers: new HttpHeaders({
            'Authorization': `Bearer ${token}`,
            'X-Localization': `${this.language}`
          })
        };
        return this.http.get<any>(
          `${environment.host}/users/myTicket`,
          httpOptions
        );
      })
    );

  }

  getMeetingSchedule(){

    return this.authService.token.pipe(
      switchMap(token => {
        const httpOptions = {
          headers: new HttpHeaders({
            'Authorization': `Bearer ${token}`,
            'X-Localization': `${this.language}`
          })
        };
        return this.http.get<any>(
          `${environment.host}/users/meetingSchedules`,
          httpOptions
        );
      })
    );

  }

  bookMeetingSchedule(id){
    return this.authService.token.pipe(
      switchMap(token => {
        const httpOptions = {
          headers: new HttpHeaders({
            'Authorization': `Bearer ${token}`,
            'X-Localization': `${this.language}`
          })
        };
        return this.http.post<any>(
          `${environment.host}/users/meetingSchedules/${id}/booked`,null,
          httpOptions
        );
      })
    );
  }

  submitAppointment(exhibitorId){
    return this.authService.token.pipe(
      take(1),
      switchMap(token => {
        let body = new FormData();
        body.append('exhibitor_id', exhibitorId);
        const httpOptions = {
          headers: new HttpHeaders({
            'Authorization': `Bearer ${token}`
          })
        };
        return this.http.post<any>(
          `${environment.chatAPI}/api/appointment`,
          body,
          httpOptions
        );
      })
    );
  }

  submitAgreement(identifier: string){
    
    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': `Bearer ${identifier}`,
        'X-Localization': `${this.language}`
      })
    };
    return this.http.put<any>(
      `${environment.host}/users/agreement`,null,
      httpOptions
    )
  }

  getDownloadCertificate(){

    return this.authService.token.pipe(
      take(1),
      switchMap(token => {        

        const httpOptions = {
          headers: new HttpHeaders({
            'Authorization': `Bearer ${token}`,
            'Accept': 'application/json'
          }),
          'responseType'  : 'arraybuffer' as 'json'
        };
        
        return this.http.get<any>(
          `${environment.host}/users/certificate`,
          httpOptions
        );
      })
    );
  }
        

}
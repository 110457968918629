import { DomSanitizer } from "@angular/platform-browser"
import { Deserializable } from "../deserializable.model"
import { BoothBannerModel } from "./boothbanner.model"
import { BrochureModel } from "./brochure.model"
import { MediaModel } from "./media.model"

export class ExhibitorHall implements Deserializable {
	
	private id: string
	private name: string
    private background_type:string = ''
    private background:string
    private image_url:string
    private description:string
	private exhibitors:EventExhibitor[]
	
	deserialize(input: any) {
		Object.assign(this, input);

		if(input.exhibitors){
			this.exhibitors = input.exhibitors.map((list:EventExhibitor) => new EventExhibitor().deserialize(list))
		}else{
			this.exhibitors = []
		}

		return this;
	}

	getId(){
		return this.id
	}

	getName(){
		return this.name
	}

	getBackgroundType(){
		return this.background_type
	}

	getBackgroundUrl(){
		return this.background
	}

	getImageUrl(){
		return this.image_url
	}

    getDescription(){
        return this.description
    }

	isIframe(){
		
		let result:boolean = false

		if(this.background_type == 'iframe'){
			result = true
		}

		return result

	}

	isImage(){
		
		let result:boolean = false

		if(this.background_type == 'image'){
			result = true
		}

		return result

	}

	isVideo(){
		
		let result:boolean = false

		if(this.background_type == 'video'){
			result = true
		}

		return result

	}

	getExhibitors(){
		return this.exhibitors
	}

	public constructor() {}
}

export class EventExhibitor implements Deserializable {
	
	private id: string
	private name: string
	private email: string
	private phone: any[] = []
	private description: string
	private icon_url: string
	private website: any[] = []
	private category:string = ""
	private brochures:BrochureModel[]
	private media:MediaModel[]
	private pic:EventExhibitorPIC[]
	private file_url:string
	private booths:any[]
	private type:any
	private contacts:any[] = []
	private clickArea:EventExhibitorClickArea
	private banner:BoothBannerModel[] = []
	private is_chat_enabled:boolean
	private is_meeting_schedule_enabled:boolean
	private is_meeting_instant_call_enabled:boolean
	private hall:string
	private zoomMeetingSeeting:any = {}
	
	deserialize(input: any) {
		Object.assign(this, input);
		
		if(input.brochures){
			this.brochures = input.brochures.map((list:BrochureModel) => new BrochureModel().deserialize(list))
		}else{
			this.brochures = []
		}

		if(input.media){
			this.media = input.media.map((list:MediaModel) => new MediaModel().deserialize(list))
		}else{
			this.media = []
		}

		if(input.pic){
			this.pic = input.pic.map((list:EventExhibitorPIC) => new EventExhibitorPIC().deserialize(list))
		}else{
			this.pic = []
		}

		if(input.exhibitors_banner){
			this.banner = input.exhibitors_banner.map((list:BoothBannerModel) => new BoothBannerModel().deserialize(list))
		}else{
			this.banner = []
		}

		this.clickArea = new EventExhibitorClickArea().deserialize(input.setting_area_click)

		if(input.setting_icons){

			if(input.setting_icons.meeting){
				this.zoomMeetingSeeting = input.setting_icons.meeting
			}

		}

		return this;
	}

	getId(){
		return this.id
	}

	getName(){
		return this.name
	}

	getHallName(){
		return this.hall
	}

	getIconUrl(){
		return this.icon_url
	}

	getFileUrl(){
		return this.file_url
	}

	getEmail(){
		return this.email
	}

	getPhone(){
		return this.phone
	}

	getPhoneArray(){

		let result = []

		if(this.contacts.length > 0){

			this.contacts.forEach((contact, index) => {

				if(contact.phone){
					result.push(contact.phone)
				}

			})

		}

		return result

	}

	getContacts(){
		return this.contacts
	}

	getDescription(){
		return this.description
	}

	getWebsite(){
		return this.website
	}

	getWebsiteArray(){

		let result = []

		if(this.contacts.length > 0){

			this.contacts.forEach((contact, index) => {

				if(contact.website){
					result.push(contact.website)
				}

			})

		}

		return result

	}

	getCategory(){
		return this.category
	}

	getBrochures(){
		return this.brochures
	}

	getMedia(){
		return this.media
	}

	getPIC(){
		return this.pic
	}

	isOperational(){
		return true
	}

	getBoothBg(){
		if(this.booths){

			return this.booths[0].file_url

		}
	}

	isLiveMeetingScheduleEnabled(){
		return this.is_meeting_schedule_enabled
	}

	isLiveMeetingInstantEnabled(){
		return this.is_meeting_instant_call_enabled
	}

	isVideoAvailable(){

		if(this.type == 'diamond' || this.type == 'titanium' || this.type == 'platinum'){
			return true
		}else{
			return false
		}

	}

	isLiveChatAvailable(){

		if(this.type == 'diamond' || this.type == 'titanium' || this.type == 'platinum'|| this.type == 'gold'){
			if(this.is_chat_enabled){
				return true
			}else{
				return false
			}
		}else{
			return false
		}

	}

	isLiveMEetingAvailable(){

		if(this.type == 'diamond' || this.type == 'titanium' || this.type == 'platinum'){
			if(this.is_meeting_schedule_enabled){
				
				if(this.isLiveMeetingInstantEnabled() || this.isLiveMeetingScheduleEnabled()){
					return true
				}else{
					return false
				}

			}else{
				return false
			}
		}else{
			return false
		}

	}

	getMarginTop(){

		return this.clickArea.getY()

	}

	getMarginLeft(){
		
		return this.clickArea.getX()

	}

	getWidth(){

		return this.clickArea.getWidth()

	}

	getHeight(){

		return this.clickArea.getHeight()

	}

	getMarginTop2(){

		return this.clickArea.getY2()

	}

	getMarginLeft2(){
		
		return this.clickArea.getX2()

	}

	getWidth2(){

		return this.clickArea.getWidth2()

	}

	getHeight2(){

		return this.clickArea.getHeight2()

	}

	getType(){
		return this.type
	}

	getClass(index){

		let text = this.type+"-0"+index

		return text

	}
	
	getBanner(){
		return this.banner
	}

	getSettingZoomMeeting(){
		return this.zoomMeetingSeeting
	}

	public constructor() {}

}

export class EventExhibitorPIC implements Deserializable {
	
	private id: string
	private name: string
	private email: string
	private image_url:string
	private is_enabled_group_chat:boolean
	private operational_end: string
	private operational_start: string
	private phone: string
	private position: string
	
	deserialize(input: any) {
		Object.assign(this, input);

		return this;
	}

	getId(){
		return this.id
	}

	getName(){
		return this.name
	}

	getImageUrl(){
		return this.image_url
	}

	getEmail(){
		return this.email
	}

	getPhone(){
		return this.phone
	}

	isLiveChat(){
		return this.is_enabled_group_chat
	}

	getOperationalStart(){
		return this.operational_start
	}

	getOperationalEnd(){
		return this.operational_end
	}

	getPosition(){
		return this.position
	}

	public constructor() {}
}

export class EventExhibitorClickArea implements Deserializable {
	
	private background_height: number = 0
	private background_width: number = 0
	private position_x: number = 0
	private position_y: number = 0
	private area_height:number = 0
	private area_width:number = 0
	private enabled:boolean = false
	private bgWidth = 1920/2
	private bgHeight = 1080/2
	private headerVar = 70/window.innerHeight
	
	deserialize(input: any) {
		Object.assign(this, input);

		return this;
	}

	getX(){

		let result = ((this.position_x - this.bgWidth) / 1920) * 100

		return result
	}

	getX2(){
		
		let result = ((this.position_x - this.bgWidth) / 1080) * 100
		result = result - (result * this.headerVar)

		return result
	}

	getY(){
		
		let result = ((this.position_y - this.bgHeight) / 1920) * 100

		return result
	}

	getY2(){
		
		let result = ((this.position_y - this.bgHeight) / 1080) * 100
		result = result - (result * this.headerVar)
		

		return result
	}

	getWidth(){
		
		let result = (this.area_width / 1920) * 100

		return result
	}

	getWidth2(){
		
		let result = (this.area_width / 1080) * 100
		result = result - (result * this.headerVar)

		return result
	}

	getHeight(){
		
		let result = (this.area_height / 1920) * 100

		return result
	}

	getHeight2(){
		
		let result = (this.area_height / 1080) * 100
		result = result - (result * this.headerVar)

		return result
	}

	public constructor() {}
}
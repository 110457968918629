import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Subject, Observable, from  } from 'rxjs';
import { map, take, tap } from 'rxjs/operators';
import { LanguageService } from './language.service';
import { formatDate } from '@angular/common';
import { AlertController, LoadingController, ToastController } from '@ionic/angular';
import { ActivatedRoute, NavigationExtras } from "@angular/router";
import { NavController } from "@ionic/angular";
import { Storage } from '@capacitor/storage';
import { EventService } from './event.service';
declare var playaudiobg: Function;
declare var pauseaudiobg: Function;
declare var changeSourceAudio: Function;

@Injectable({
  providedIn: 'root'
})
export class GeneralService {

  language;

  constructor(
    private alertCtrl: AlertController,
    private http: HttpClient,
    private languageService: LanguageService,
    private navCtrl:NavController,
    private toastController: ToastController,
    private eventService: EventService,
    private loadingCtrl: LoadingController
  ) { 

    this.language = this.languageService.language();

  }


  getBannerHome(){
    return this.http.get<any>(
      `${environment.host}/slideShows?type=slider`
    );
  }
  getSettingsContent(content){
    const httpOptions = {
      headers: new HttpHeaders({
        'X-Localization': `${this.language}`
      })
    };

    return this.http.get<any>(
      `${environment.host}/contents?key=${content}`
    );
  }

  getFeaturedHome(){
    return this.http.get<any>(
      `${environment.host}/slideShows?type=featured`
    );
  }

  getCategoryList() {
    
    const httpOptions = {
      headers: new HttpHeaders({
        'X-Localization': `en`
      })
    };

    return this.http.get<any>(
      `${environment.host}/categories`, httpOptions
    );

  }

  getInterestList(){

    return this.http.get<any>(
      `${environment.host}/interests`
    );

  }

  getPaymentChannel() {
    
    return this.http.get<any>(
      `${environment.host}/paymentChannels`
    );

  }

  // getPersonalEventContent() {
    
  //   return this.http.get<any>(
  //     `${environment.baseUrl}/api/ajukan_sekarang`
  //   );

  // }

  // getPersonalEventList() {
    
  //   return this.http.get<any>(
  //     `${environment.baseUrl}/api/event_ajukan_sekarang`
  //   );

  // }

  // getPersonalEventPaymentList() {
    
  //   return this.http.get<any>(
  //     `${environment.baseUrl}/api/payment_ajukan_sekarang`
  //   );

  // }

  getWeekendIncrement(day){

    let increment = 0;

    if(day == "Monday"){

      increment = 5

    }

    if(day == "Tuesday"){

      increment = 4

    }

    if(day == "Wednesday"){

      increment = 3

    }

    if(day == "Thursday"){

      increment = 2

    }

    if(day == "Friday"){

      increment = 1

    }

    if(day == "Saturday"){

      increment = 0

    }

    if(day == "Sunday"){

      increment = -1

    }

    return increment;

  }

  getDayMonthFormat(fulldate){

    return formatDate(new Date(fulldate), 'yyyy-MM-dd', 'en-US');

  }

  setRouterHistory(route){

    from(Storage.get({key: 'historyrouter'})).subscribe(

      res => {

        if(res.value){

          let tmpArray = JSON.parse(res.value)

          if(tmpArray[tmpArray.length - 1] != route){

            if(tmpArray.length > 5){

              tmpArray.splice(0, 1)
              tmpArray.push(route)
  
            }else{
  
              tmpArray.push(route)
  
            }

          }

          Storage.set({key: 'historyrouter', value: JSON.stringify(tmpArray)});

        }else{

          let tmpArray = []

          tmpArray.push(route)

          Storage.set({key: 'historyrouter', value: JSON.stringify(tmpArray)});

        }

      },
      err => {

        // console.log(err)

      }

    )

  }

  resetHistoryRoute(){
  
    let tmpArray = []

    // console.log(tmpArray)

    Storage.set({key: 'historyrouter', value: JSON.stringify(tmpArray)});
  
  }
  showAlert(message) {
    this.alertCtrl.create({
      mode: 'ios',
      cssClass: 'custom-alert',
      message: message,
      buttons: ['OK']
    })
      .then(alert => {
        alert.present();
      });
  }

  getHistoryRoute(){

    return from(Storage.get({key: 'historyrouter'})).pipe(
      map(historyrouter => {
        
        if(historyrouter){

          return JSON.parse(historyrouter.value)

        }else{

          return []

        }

      })
    );

  }
  openPrivateMessage(data){
    let navigationExtras: NavigationExtras = {
        queryParams: {uts:btoa(JSON.stringify(data)), type:"1"}
    };
    this.navCtrl.navigateForward(['/message'], navigationExtras);
  }

  openPrivateMessageInformation(data){
    let navigationExtras: NavigationExtras = {
        queryParams: {uts:btoa(JSON.stringify(data)), type:"2"}
    };
    this.navCtrl.navigateForward(['/message'], navigationExtras);
  }

  openPrivateMessageExhibitor(data){
    
    // console.log(data)

    let tmp = {
      "name" : data.getName(),
      "email" : data.getEmail(),
      "id": data.getId()
    }

    let navigationExtras: NavigationExtras = {
        queryParams: {uts:btoa(JSON.stringify(tmp)), type:"3"}
    };

    // console.log(navigationExtras)

    this.navCtrl.navigateForward(['/message'], navigationExtras);
  }
 

  getDayMonthForamt(fulldate){
    return formatDate(new Date(fulldate), 'yyyy-MM-dd', 'en-US')
  }

  getMonthForamt(fulldate){
    return formatDate(new Date(fulldate), 'MM', 'en-US')
  }

  getErrMessage(err){
    return err.error.message
  }

  getErrMessageCode(err, field){
    return err.error.errors.code[0]
  }

  getErrMessageField(err, field){
    
    let error = err.error.errors
    
    if(error[field]){
      return error[field][0]
    }else{
      return ''
    }

    
  }

  async presentToast(message) {
    const toast = await this.toastController.create({
      message: message,
      duration: 2000
    });
    toast.present();
  }

  chatAttachFiles(image) {
    const httpOptions = {
      headers: new HttpHeaders({
          'Cache-Control': 'no-cache',
          // 'Authorization': 'Bearer' + authData.token
      })
    };
    
    const body = new FormData();
    body.append('file',image);
    
    return this.http.post<any>(`${environment.chatAPI}/api/file/upload`,body);
  }

  checkAudioBgFLag(){

    return localStorage.getItem('bgAudio')

  }

  setAudioBgFlag(value){

    localStorage.setItem('bgAudio', value);

  }

  playAudioBG(){
    playaudiobg()
  }

  pauseAudioBG(){
    pauseaudiobg()
  }

  changeSource(page){
    changeSourceAudio(page)
  }

  downloadUrl(url, name){
    this.loadingCtrl.create({ keyboardClose: true })
      .then(loading => {
        loading.present();
        this.eventService.getDownloadFile(url).subscribe(
          res => {
            loading.dismiss()

            let file = new Blob([res]);            
            var fileURL = URL.createObjectURL(file);
            // window.open(fileURL);
            var link = document.createElement('a');
            link.href = fileURL

            const _url = new URL(url)
            const extension = _url.pathname.split(".")

            var fileName = name.replace(" ", "_") + "."+ extension[extension.length - 1];
            link.download = fileName;
            link.click();

          },
          err => {
            loading.dismiss()
          }
        )
    })

  }

  emergencyJSON() {
    return this.http.get<any>(
      `assets/bypass.json`
    );
  }

}

import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { ModalController, NavController } from '@ionic/angular';
import { FaqPage } from 'src/app/event/faq/faq.page';
import { LeaderboardPage } from 'src/app/event/leaderboard/leaderboard.page';
import { MissionPage } from 'src/app/event/mission/mission.page';
import { MyPointPage } from 'src/app/event/my-point/my-point.page';
import { PhotoboothPage } from 'src/app/event/photobooth/photobooth.page';
import { PrivacyPolicyEventPage } from 'src/app/event/privacy-policy-event/privacy-policy-event.page';
import { TermConditionEventPage } from 'src/app/event/term-condition-event/term-condition-event.page';
import { TutorialPage } from 'src/app/event/tutorial/tutorial.page';
import { AuthService } from 'src/app/service/auth.service';
import { Storage } from '@capacitor/storage';
import { User } from 'src/app/models/user.model';
import { EventService } from 'src/app/service/event.service';
import { environment } from 'src/environments/environment';
import { EventPointModel } from 'src/app/models/EventModel/point.model';
import { MemberService } from 'src/app/service/member.service';
import { Router } from '@angular/router';
import {Echo} from 'laravel-echo-ionic';
import { ChatService } from 'src/app/service/chat.service';


@Component({
  selector: 'app-header-event',
  templateUrl: './header-event.component.html',
  styleUrls: ['./header-event.component.scss'],
})
export class HeaderEventComponent implements OnChanges {
  @Input() position:any = "";
  @Input() parentFlag:boolean = false;
  @Input() isReplaceUrl:boolean = false;
  @Input() changeFlag:boolean = false;
  public isCollapsed = false;
  panelOpenState = false;

  isAuthenticated:any
  user:User = new User().deserialize("{}")
  myPoint:EventPointModel = new EventPointModel().deserialize("{}")
  totalParticipants : any
  myTicket:any = ''

  hideTicekt:any[] = environment.hideTicketName

  laravelEchoRoom:any;
  laravelEchoNotif:any;
  chatCLient:any = environment.chatClient
  keyPrefix:any=environment.keyPrefix;

  thisRouterUrl:any = ''

  isNewChat:boolean = false
  isNewNotif:boolean = false;

  constructor(
    private modalController: ModalController,
    private authService: AuthService,
    private eventService: EventService,
    private memberService: MemberService,
    private navCtrl: NavController,
    private router: Router,
    private chatService: ChatService
  ) {
    this.thisRouterUrl = this.router.url
  }
  ngOnChanges(changes: SimpleChanges): void {
    
    this.checkAuth()

  }

  ngOnInit() {

    this.eventService.getNotifFlagObserver().subscribe(

      val => {

        if(val != 0){

          localStorage.setItem('isNewMessage', "true")
          this.isNewNotif = true

        }

      }

    )

    this.eventService.getFlagMessageObserver().subscribe(

      val => {

        if(val != 0){

          localStorage.setItem('isNewChat', "true")
          this.isNewChat = true

        }

      }

    )

  }

  checkAuth(){
    console.log("ini dari header")
    this.authService.userIsAuthenticated.subscribe(
      data => {
        console.log(data)
        if(data){

          this.isAuthenticated = data

          this.authService.user.subscribe(user => {
            this.user = new User().deserialize(user) ;
            console.log(this.user)
            // this.getMyPoint()
            // this.getParticipantList()
            // this.getMyTicket()

            // console.log(user)
          });

        }else{

          this.isAuthenticated = data

          this.authService.token.subscribe(
            res => {

              if(res){
                // this.logout()
              }else{
                Storage.remove({key: 'authData'});
                Storage.remove({key: 'tokenData'});
              }

            }
          );

          // 

        }
      }
    )

  }

  getMyTicket(){

    this.memberService.getMyTicketType().subscribe(
      res =>{

        this.myTicket = res.data

      }
    )

  }

  getMyPoint(){

    // this.eventService.getMyPoint(environment.eventID).subscribe(
    //   res =>{

    //     this.myPoint = new EventPointModel().deserialize(res.data)

    //   }
    // )

  }

  getParticipantList(){
    this.eventService.getEventParticipantCount(environment.eventID).subscribe(
      res =>{
        this.totalParticipants = res.total
      }
    )
  }


  termsConditions(){
    this.modalController
      .create({
        component: TermConditionEventPage,
        cssClass: "halfmodal",
      })
      .then((modalElement) => {
        modalElement.present();
      });
  } 

  privacyPolicy(){
    this.modalController
      .create({
        component: PrivacyPolicyEventPage,
        cssClass: "halfmodal",
      })
      .then((modalElement) => {
        modalElement.present();
      });
  } 

  photobooth(){
    // this.modalController
    //   .create({
    //     component: PhotoboothPage,
    //     cssClass: "smallmodal",
    //   })
    //   .then((modalElement) => {
    //     modalElement.present();
    //   });

    this.navCtrl.navigateRoot("/photobooth")
    localStorage.setItem('backUrlPhotobooth', this.router.url);

  } 
  
  logout() {
    this.memberService.logout().subscribe(res => {
      Storage.remove({key: 'authData'});
      Storage.remove({key: 'tokenData'});

      this.navCtrl.navigateRoot('');

    },
    err => {

      Storage.remove({key: 'authData'});
      Storage.remove({key: 'tokenData'});
      this.navCtrl.navigateRoot('');

    });;
  }

  // async leaderboard(){
  //   const modal = await this.modalController.create({
  //     component:LeaderboardPage,
  //     cssClass: 'halfmodal',
  //   });
  //   return await modal.present();
  // } 

  allowTicket(ticketName){

    let result:boolean = false
    if(this.hideTicekt.findIndex(x => x == ticketName) == -1){
      result = true
    }else{
      result = false
    }
    

    return result

  }

  getTotoalChatUnread(){

    this.chatService.getTotalUnread(this.chatCLient, this.user.email).subscribe(

      res => {
        if(res.data > 0){

          this.isNewChat = true

        }else{

          this.isNewChat = false

        }
      }

    )

  }

  startListenMessage(){

    if(localStorage.getItem('isNewChat')){

      this.isNewChat = true

    }else{

      this.isNewChat = false

    }

  }

  stopListenMessage(){
    
  }

  startListenNotif(){

    if(localStorage.getItem('isNewMessage')){

      this.isNewNotif = true

    }else{

      this.isNewNotif = false

    }

  }

  stopListenNotif(){


  }

}

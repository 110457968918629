import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { ModalController, NavController } from '@ionic/angular';
import { User } from 'src/app/models/user.model';
import { AuthService } from 'src/app/service/auth.service';
import { EventService } from 'src/app/service/event.service';
import { MemberService } from 'src/app/service/member.service';
import { Storage } from '@capacitor/storage';
import { environment } from 'src/environments/environment';
import { EventPointModel } from 'src/app/models/EventModel/point.model';
import { Router } from '@angular/router';
import {Echo} from 'laravel-echo-ionic';
import { ChatService } from 'src/app/service/chat.service';

@Component({
  selector: 'app-header-mobile',
  templateUrl: './header-mobile.component.html',
  styleUrls: ['./header-mobile.component.scss'],
})
export class HeaderMobileComponent implements OnChanges {
  active = false;
  isAuthenticated:boolean = false;
  @Input() position_mobile:any = "";
  @Input() isReplaceUrl:boolean = false;
  @Input() changeFlag:boolean = false;

  user:User = new User().deserialize("{}")

  myTicket:any = ''
  myPoint:EventPointModel = new EventPointModel().deserialize("{}")
  
  isCollapsedMenu1: boolean = false;
  isCollapsedMenu2: boolean = false;
  isCollapsedMenu3: boolean = false;
  isCollapsedMenu4: boolean = false;

  hideTicekt:any[] = environment.hideTicketName

  laravelEchoRoom:any;
  laravelEchoNotif:any;
  chatCLient:any = environment.chatClient
  keyPrefix:any=environment.keyPrefix;

  thisRouterUrl:any = ''

  isNewChat:boolean = false
  isNewNotif:boolean = false;

  constructor(
    private modalController: ModalController,
    private authService: AuthService,
    private eventService: EventService,
    private memberService: MemberService,
    private navCtrl: NavController,
    private router: Router,
    private chatService: ChatService
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    
    this.checkAuth()

  }

  ngOnInit() {

    // this.checkAuth()
    this.eventService.getNotifFlagObserver().subscribe(

      val => {

        if(val != 0){

          localStorage.setItem('isNewMessage', "true")
          this.isNewNotif = true

        }

      }

    )

    this.eventService.getFlagMessageObserver().subscribe(

      val => {

        if(val != 0){

          localStorage.setItem('isNewChat', "true")
          this.isNewChat = true

        }

      }

    )

  }

  openMenu() {
		this.active = true;
  }

  checkAuth(){

    this.authService.userIsAuthenticated.subscribe(
      data => {
        if(data){

          this.isAuthenticated = data

          this.authService.user.subscribe(user => {
            this.user = new User().deserialize(user) ;
            // this.getMyPoint()
            // this.getParticipantList()
            this.getMyTicket()
            // console.log(user)

            // if(!this.laravelEchoRoom){

            //   this.laravelEchoRoom = new Echo({
            //     broadcaster: "socket.io",
            //     host: environment.socketUrlChat,
            //   });
          
              
            // }

            // if(!this.laravelEchoNotif){

            //   this.authService.token.subscribe((token) => {

            //     this.laravelEchoNotif = new Echo({
            //       broadcaster: "socket.io",
            //       host: environment.socketUrl,
            //       auth: {
            //         headers: {
            //           Authorization : "Bearer " + token
            //         }
            //       }
            //     });
          
            //   })

            // }

          });

        }else{

          this.isAuthenticated = data

          this.authService.token.subscribe(
            res => {

              if(res){
                // this.logout()
              }else{
                Storage.remove({key: 'authData'});
                Storage.remove({key: 'tokenData'});
              }

            }
          );

          // 

        }
      }
    )

  }

  getMyTicket(){

    // this.memberService.getMyTicketType().subscribe(
    //   res =>{

    //     this.myTicket = res.data

    //   }
    // )

  }

  getMyPoint(){

    // this.eventService.getMyPoint(environment.eventID).subscribe(
    //   res =>{

    //     this.myPoint = new EventPointModel().deserialize(res.data)

    //   }
    // )

  }

  logout() {
    this.memberService.logout().subscribe(res => {
      Storage.remove({key: 'authData'});
      Storage.remove({key: 'tokenData'});

      this.closeMenu()
      this.navCtrl.navigateRoot('');

    },
    err => {

      this.closeMenu()
      Storage.remove({key: 'authData'});
      Storage.remove({key: 'tokenData'});
      this.navCtrl.navigateRoot('');

    });;
  }
  
	closeMenu() {
		this.active = false;
	}

  photobooth(){
    // this.modalController
    //   .create({
    //     component: PhotoboothPage,
    //     cssClass: "smallmodal",
    //   })
    //   .then((modalElement) => {
    //     modalElement.present();
    //   });

    this.navCtrl.navigateRoot("/photobooth")
    localStorage.setItem('backUrlPhotobooth', this.router.url);

  } 

  allowTicket(ticketName){

    let result:boolean = false
    if(this.hideTicekt.findIndex(x => x == ticketName) == -1){
      result = true
    }else{
      result = false
    }
    

    return result

  }

  getTotoalChatUnread(){

    this.chatService.getTotalUnread(this.chatCLient, this.user.email).subscribe(

      res => {
        if(res.data > 0){

          this.isNewChat = true

        }else{

          this.isNewChat = false

        }
      }

    )

  }

  startListenMessage(){

    if(localStorage.getItem('isNewChat')){

      this.isNewChat = true

    }else{

      this.isNewChat = false

    }

  }

  stopListenMessage(){
    
  }

  startListenNotif(){

    if(localStorage.getItem('isNewMessage')){

      this.isNewNotif = true

    }else{

      this.isNewNotif = false

    }

  }

  stopListenNotif(){


  }

}

import { Deserializable } from "../deserializable.model";

export class ScavengerHuntsModel implements Deserializable{

    private id:string
	private event_id:string
    private schedule_name:string
    private schedule_period:string
    private page_name:string
    private hunt_name:string
    private hunt_asset_url:string
    private hunt_trigger_points_url:string

	deserialize(input: any) {
		Object.assign(this, input);
        
		return this;
	}

    getID(){
        return this.id
    }

	getEventID(){
		return this.event_id
	}

	getScheduleName(){
		return this.schedule_name
	}

    getSchedulePeriod(){
        return this.schedule_period
    }

    getPageName(){
        return this.page_name
    }

    getHuntName(){
        return this.hunt_name
    }

    getAssetUrl(){
        return this.hunt_asset_url
    }

    getHuntTriggerPointUrl(){
        return this.hunt_trigger_points_url
    }

}
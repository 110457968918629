import { Deserializable } from "../deserializable.model";
import { EventMatchInterest } from "./event.model";

export class EventPointModel implements Deserializable{

	private rank:string
    private points:string

	deserialize(input: any) {
		Object.assign(this, input);
		return this;
	}

	getRank(){
		return this.rank
	}

	getPoints(){
		return this.points
	}

}

export class EventHowToPlayModel implements Deserializable{

	private play:string
    private get_point:string

	deserialize(input: any) {
		Object.assign(this, input);
		return this;
	}

	getHowToPlay(){
		return this.play
	}

	getPoint(){
		return this.get_point
	}

}

export class EventLeaderboardModel extends EventMatchInterest{

    private points_sum_points_earned:string
	private agency:string

	deserialize(input: any) {
		Object.assign(this, input);

		if(input.additional_data){
			this.agency = input.additional_data.agency
		}else{
			this.agency = ''
		}
		

		return this;
	}

	getPoint(){
		return this.points_sum_points_earned
	}

	getAgency(){
		return this.agency
	}

}

export class EventPointHistoryModel implements Deserializable{

    private id:string
    private points_earned:number
    private description:string
    private created_at:string

	deserialize(input: any) {
		Object.assign(this, input);
		return this;
	}

    getCreateAt(){
        return this.created_at
    }

    getDescription(){
        return this.description
    }

	getPoint(){
		return this.points_earned
	}

}
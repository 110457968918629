export const environment = {
  production: false,
  host: "https://cms-jif2021.kelolain.id/api",
  lang: 'en',
  eventID: '1',
  capchaSiteKey: '6LfDAp0aAAAAAPXpWoP14DnuA50f00-5ZMnaXhPk',
  baseUrl: 'https://ikf2021.kelolain.id/',
  succesOrderRedirect: 'https://ikf2021.kelolain.id/payment/success',
  apsaraUrl: 'https://cms-jif2021.kelolain.id',
  firebase : {
    apiKey: "AIzaSyCTGxieyktAwQ7Nsin32YvrsydgXT-kbwk",
    authDomain: "newlaunch-ea619.firebaseapp.com",
    databaseURL: "https://jif2021.asia-southeast1.firebasedatabase.app/",
    projectId: "newlaunch-ea619",
    storageBucket: "newlaunch-ea619.appspot.com",
    messagingSenderId: "513186199428",
    appId: "1:513186199428:web:cfd83635af133381218fd1",
    measurementId: "G-DVYLT114QP"
  },
  zoom : {
    apiKey: "jj_mev97TVSjc98mY7Qohg",
    apiSecret: "JwZmABmAlslp9twCtM4MoRE6HJGUWNDPeiGa"
  },
  zoomweb : {
    apiKey: "jj_mev97TVSjc98mY7Qohg",
    apiSecret: "JwZmABmAlslp9twCtM4MoRE6HJGUWNDPeiGa"
  },
  chatClient:"jif2021",
  showLiveChat:'ok',
  isParticipantTypeSelect:true,
  chatAPI:"https://broadcast.unictdev.com",
  socketUrlChat:"https://livechat-jif2021.kelolain.id:6001/",
  keyPrefix:"unictive_quiz",
  heyzo:"e05ce84cc6d34a76a7d9de080c34d9a5",
  defaultChatText:'Selamat datang di Live Chat JIF2021.<br>Kami siap melayani setiap: Hari Senin - Minggu Pukul 08.30 - 17.30 WIB<br>Dengan senang hati kami akan memberikan informasi yang Bapak/Ibu butuhkan.',
  socketUrl:"https://broadcast.unictdev.com:6003",
  roomKnowledgeSharing:'1',
  roomMainHall:'2',
  notificationTimer:20000,
  hideTicekt: [7, 8],
  hideTicketName: ['Ticket VIP', 'Ticket Exhibitor'],
  iframePhotoBooth:"https://cms-jif2021.kelolain.id/photobooth/index.html",
  iframePost:"https://cms-jif2021.kelolain.id",
  linkMiniGame: [
    'https://cms-jif2021.kelolain.id/game/iforterun/index.html',
    'https://cms-jif2021.kelolain.id/game/penalty/index.html',
    'https://cms-jif2021.kelolain.id/game/memory/index.html'
  ],
  nameMiniGame: [
    'IForte Run',
    'IKF Kicks',
    'BCA Memory Matching'
  ],
  encryptKey: '1324576890abcdef1324576890abcdef',
  encryptIV: 'abcdef1324576890abcdef1324576890',
  chatFilePath: 'https://broadcast.unictdev.com/assets/uploads/chat/',
  disableRigthClick: ['entry', 'order-data', 'personal-data'],
  defaultApiErrorMessage: 'Please try again later',
  prefixRoomDataLivechat:'jif2021/masterlivechat'
};

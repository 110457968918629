import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './service/auth.guard';

const routes: Routes = [
  {
    path: 'home',
    loadChildren: () => import('./home/home.module').then( m => m.HomePageModule)
  },
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full'
  },
  {
    path: 'entry',
    loadChildren: () => import('./confirmation/input-ticket/input-ticket.module').then( m => m.InputTicketPageModule)
  },
  {
    path: 'entry/:code',
    loadChildren: () => import('./confirmation/input-ticket/input-ticket.module').then( m => m.InputTicketPageModule)
  },
  {
    path: 'personal-data/:code',
    loadChildren: () => import('./confirmation/personal-data/personal-data.module').then( m => m.PersonalDataPageModule)
  },
  {
    path: 'verification-code',
    loadChildren: () => import('./confirmation/verification-code/verification-code.module').then( m => m.VerificationCodePageModule)
  },
  {
    path: 'verification-code/:code',
    loadChildren: () => import('./confirmation/verification-code/verification-code.module').then( m => m.VerificationCodePageModule)
  },
  {
    path: 'pin-code',
    loadChildren: () => import('./confirmation/pin-code/pin-code.module').then( m => m.PinCodePageModule)
  },
  {
    path: 'term-condition',
    loadChildren: () => import('./term-condition/term-condition.module').then( m => m.TermConditionPageModule)
  },
  {
    path: 'ticket-list',
    loadChildren: () => import('./confirmation/ticket-list/ticket-list.module').then( m => m.TicketListPageModule)
  },
  {
    path: 'order-data',
    loadChildren: () => import('./confirmation/order-data/order-data.module').then( m => m.OrderDataPageModule)
  },
  {
    path: 'checkout',
    loadChildren: () => import('./confirmation/checkout/checkout.module').then( m => m.CheckoutPageModule)
  },
  {
    path: 'payment',
    loadChildren: () => import('./confirmation/payment/payment.module').then( m => m.PaymentPageModule)
  },
  {
    path: 'event',
    loadChildren: () => import('./event/event/event.module').then( m => m.EventPageModule)
  },
  {
    path: 'entrance',
    canActivate: [AuthGuard],
    loadChildren: () => import('./event/entrance/entrance.module').then( m => m.EntrancePageModule)
  },
  {
    path: 'lobby',
    canActivate: [AuthGuard],
    loadChildren: () => import('./event/lobby/lobby.module').then(m => m.LobbyPageModule)
  },
  {
    path: 'friend-list',
    loadChildren: () => import('./event/friend-list/friend-list.module').then( m => m.FriendListPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'notification',
    loadChildren: () => import('./event/notification/notification.module').then( m => m.NotificationPageModule),
    canActivate: [AuthGuard]
  },
  // {
  //   path: 'briefcase',
  //   loadChildren: () => import('./event/briefcase/briefcase.module').then( m => m.BriefcasePageModule),
  //   canActivate: [AuthGuard]
  // },
  {
    path: 'brochure',
    loadChildren: () => import('./event/briefcase/briefcase.module').then( m => m.BriefcasePageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'main-hall',
    loadChildren: () => import('./event/main-hall/main-hall.module').then( m => m.MainHallPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'exhibition-hall',
    loadChildren: () => import('./event/exhibition-hall/exhibition-hall.module').then( m => m.ExhibitionHallPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'exhibition-hall/:hallID',
    loadChildren: () => import('./event/booth/booth.module').then( m => m.BoothPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'my-progress',
    loadChildren: () => import('./event/my-progress/my-progress.module').then( m => m.MyProgressPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'myprogress-detail',
    loadChildren: () => import('./event/my-progress/detail/detail.module').then( m => m.DetailPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'redemption',
    loadChildren: () => import('./event/redemption/redemption.module').then( m => m.RedemptionPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'networking-lounge-list',
    loadChildren: () => import('./event/networking-lounge/networking-lounge.module').then( m => m.NetworkingLoungePageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'my-ticket',
    canActivate: [AuthGuard],
    loadChildren: () => import('./confirmation/my-ticket/my-ticket.module').then( m => m.MyTicketPageModule)
  },
  {
    path: 'my-transaction',
    canActivate: [AuthGuard],
    loadChildren: () => import('./confirmation/my-transaction/my-transaction.module').then( m => m.MyTransactionPageModule)
  },
  {
    path: 'edit-profile',
    canActivate: [AuthGuard],
    loadChildren: () => import('./confirmation/edit-profile/edit-profile.module').then( m => m.EditProfilePageModule)
  },
  {
    path: 'change-pincode',
    loadChildren: () => import('./confirmation/change-pincode/change-pincode.module').then( m => m.ChangePincodePageModule)
  },
  {
    path: 'mission',
    loadChildren: () => import('./event/mission/mission.module').then( m => m.MissionPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'leaderboard',
    loadChildren: () => import('./event/leaderboard/leaderboard.module').then( m => m.LeaderboardPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'my-point',
    loadChildren: () => import('./event/my-point/my-point.module').then( m => m.MyPointPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'faq',
    loadChildren: () => import('./event/faq/faq.module').then( m => m.FaqPageModule)
  },
  {
    path: 'tutorial',
    loadChildren: () => import('./event/tutorial/tutorial.module').then( m => m.TutorialPageModule)
  },
  {
    path: 'survey',
    loadChildren: () => import('./event/survey/survey.module').then( m => m.SurveyPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'survey-success',
    loadChildren: () => import('./event/survey/survey-success/survey-success.module').then( m => m.SurveySuccessPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'live-chat',
    loadChildren: () => import('./event/live-chat/live-chat.module').then( m => m.LiveChatPageModule)
  },
  {
    path: 'term-condition-event',
    loadChildren: () => import('./event/term-condition-event/term-condition-event.module').then( m => m.TermConditionEventPageModule)
  },
  {
    path: 'privacy-policy-event',
    loadChildren: () => import('./event/privacy-policy-event/privacy-policy-event.module').then( m => m.PrivacyPolicyEventPageModule)
  },
  {
    path: 'session/:sessionID',
    loadChildren: () => import('./event/session/session.module').then( m => m.SessionPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'message',
    loadChildren: () => import('./event/message/message.module').then( m => m.MessagePageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'speaker-detail',
    loadChildren: () => import('./speaker-detail/speaker-detail.module').then( m => m.SpeakerDetailPageModule)
  },
  {
    path: 'auction',
    loadChildren: () => import('./event/auction/auction.module').then( m => m.AuctionPageModule)
  },
  {
    path: 'payment-success',
    loadChildren: () => import('./confirmation/payment/success/success.module').then( m => m.SuccessPageModule)
  },
  // {
  //   path: 'booth',
  //   loadChildren: () => import('./event/booth/booth.module').then( m => m.BoothPageModule)
  // },
  {
    path: 'participant',
    loadChildren: () => import('./event/participant/participant.module').then( m => m.ParticipantPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'countdown',
    canActivate: [AuthGuard],
    loadChildren: () => import('./event/countdown/countdown.module').then( m => m.CountdownPageModule)
  },
  {
    path: 'upgrade-ticket',
    loadChildren: () => import('./event/upgrade-ticket/upgrade-ticket.module').then( m => m.UpgradeTicketPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'confirm-connection',
    loadChildren: () => import('./popup/confirm-connection/confirm-connection.module').then( m => m.ConfirmConnectionPageModule)
  },
  {
    path: 'confirm-connection-message',
    loadChildren: () => import('./popup/confirm-connection-message/confirm-connection-message.module').then( m => m.ConfirmConnectionMessagePageModule)
  },
  {
    path: 'modal-videocall',
    loadChildren: () => import('./popup/modal-videocall/modal-videocall.module').then( m => m.ModalVideocallPageModule)
  },
  {
    path: 'meeting-schedule',
    loadChildren: () => import('./event/meeting-schedule/meeting-schedule.module').then( m => m.MeetingSchedulePageModule)
  },
  {
    path: 'halloffame',
    loadChildren: () => import('./event/halloffame/halloffame.module').then( m => m.HalloffamePageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'knowledge-sharing',
    loadChildren: () => import('./event/breakout/detail/detail.module').then( m => m.DetailPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'mini-stage',
    loadChildren: () => import('./event/breakout/detail/detail.module').then( m => m.DetailPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'networking-lounge',
    loadChildren: () => import('./event/networking-lounge-single/networking-lounge-single.module').then( m => m.NetworkingLoungeSinglePageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'mini-game',
    loadChildren: () => import('./event/mini-game/mini-game.module').then( m => m.MiniGamePageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'session-testing',
    loadChildren: () => import('./event/session-testing/session-testing.module').then( m => m.SessionTestingPageModule)
  },
  {
    path: 'session-detail',
    loadChildren: () => import('./session-detail/session-detail.module').then( m => m.SessionDetailPageModule)
  },
  {
    path: 'my-certificate',
    loadChildren: () => import('./confirmation/my-certificate/my-certificate.module').then( m => m.MyCertificatePageModule)
  },
  {
    path: 'photobooth',
    loadChildren: () => import('./event/photobooth-iframe/photobooth-iframe.module').then( m => m.PhotoboothIframePageModule)
  },
  {
    path: 'photobooth-ionic',
    loadChildren: () => import('./event/photobooth/photobooth.module').then( m => m.PhotoboothPageModule)
  },
  {
    path: 'material',
    loadChildren: () => import('./event/material/material.module').then( m => m.MaterialPageModule)
  },  {
    path: 'dual-login',
    loadChildren: () => import('./dual-login/dual-login.module').then( m => m.DualLoginPageModule)
  }







];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }

import { Deserializable } from "./deserializable.model"

export interface MyEvent {
	event_id: number,
	event_name: string,
	event_image: string,
	event_location: string,
	event_start_date: string,
	event_end_date: string
}

export class User implements Deserializable {
	
	public id: string
	public name: string
	public email: string
	public phone: string
	public agency: string
	public company_position: string
	public photo_url: string
	public interests: string
	public phonenocode:string
	public participant_type:string

	public constructor(
		
	) {}

	deserialize(input: any) {
		Object.assign(this, input);

		try{

			this.phonenocode = input.phone.substring(2)

		}catch(e){

		}

		return this;
	}

	getID(){
		return this.id
	}

	getName(){
		return this.name
	}

	getInitial(){
		
		if(this.name){
			let member_name = this.name.split(" ");
			if (member_name.length > 1) {
			
				return member_name[0].charAt(0).toUpperCase() + member_name[1].charAt(0).toUpperCase();
			
			} else {

				return member_name[0].charAt(0).toUpperCase();
			
			}
		}

	}

	getAgency(){
		return this.agency
	}

	getEmail(){
		return this.email
	}

	getPhone(){
		return this.phone
	}

	getPhoneNoCode(){
		return this.phone.substring(2)
	}

	getParticipantType(){
		return this.participant_type
	}

}


export class Connections {
	public constructor(
		public id: string,
		public name: string,
		public email: string,
		public phone: string,
		public company_name: string,
		public company_position: string,
		public photo_url: string,
		public interests: string
	) {}
}

import { Component, OnInit } from "@angular/core";
import { LoadingController, ModalController, NavParams } from "@ionic/angular";
import { EventParticipant } from "src/app/models/EventModel/participant.model";
import { ConnectionsService } from "src/app/service/connections.service";
import { GeneralService } from "src/app/service/general.service";
import { environment } from "src/environments/environment";
import {ConfirmConnectionMessagePage} from "../confirm-connection-message/confirm-connection-message.page";
@Component({
  selector: 'app-confirm-connection',
  templateUrl: './confirm-connection.page.html',
  styleUrls: ['./confirm-connection.page.scss'],
})
export class ConfirmConnectionPage implements OnInit {
  participant:EventParticipant = new EventParticipant().deserialize("{}");
  constructor(
    private modalController: ModalController,
    public navParams:NavParams,
    private loadingCtrl:LoadingController,
    private connectionsService:ConnectionsService,
    private generalService: GeneralService
  ) { }

 close() {
    this.modalController.dismiss("submit_err");
 }

 cancel(){
   this.modalController.dismiss('cancel')
 }

 submitOk(){
  this.modalController.dismiss('submit_ok')
}

 next(){

    let id =this.participant.getID();
    let message='';

    this.loadingCtrl.create({ keyboardClose: true })
      .then(loading => {    
              loading.present();
              this.connectionsService.addConnect(id,message).subscribe(
                res => {
                  this.submitOk();
                  loading.dismiss()
                  this.generalService.showAlert(res.message);
                },
                err => {
                  this.close();

                  let message = this.generalService.getErrMessage(err)

                  if(message && message != ''){
                    this.generalService.showAlert(message)
                  }else{
                    this.generalService.showAlert(environment.defaultApiErrorMessage)
                  }
                  
                  loading.dismiss()
                },
              )
            }
        )
    
  }

  ngOnInit(){
    this.participant =  this.navParams.get('participant');
  }
  
}

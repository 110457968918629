import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map, take, switchMap, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { AuthService } from './auth.service';
import { Subject, Observable, from, BehaviorSubject  } from 'rxjs';
// import { NetworkService, ConnectionStatus } from './network.service';
// import { OfflineManagerService } from './offline-manager.service';
// import { Storage } from '@ionic/storage';
import { LanguageService } from './language.service';

@Injectable({
  providedIn: 'root'
})
export class EventService {

  private sbj = new Subject<any>();
  private sjbFilter = new Subject<any>();
  private sbjWaktu = new Subject<any>();
  private sbjAddress = new Subject<any>();
  language;

  private notifFlag = new BehaviorSubject(0)
  private notifMessage = new BehaviorSubject(0)

  constructor(
    private http: HttpClient,
    private authService: AuthService,
    // private networkService: NetworkService,
    // private storage: Storage,
    // private offlineManager: OfflineManagerService,
    private languageService: LanguageService
  )
  {
    this.language = this.languageService.language();
  }


  pubSbjFilter(data: any) { this.sjbFilter.next(data); }
  obvSbjFilter(): Subject<any> { return this.sjbFilter; }


  pubSjbWaktuFilter(data: any) { this.sbjWaktu.next(data); }
  obvSjbWaktuFilter(): Subject<any> { return this.sbjWaktu; }


  pubFilter(data: any) { this.sbj.next(data); }
  obvFilter(): Subject<any> { return this.sbj; }


  pubAddress(data: any) { this.sbjAddress.next(data); }
  obvAddress(): Subject<any> { return this.sbjAddress; }

  getEventDetail(eventSlug: string) {
    return this.http.get<any>(
      `${environment.host}/events/${eventSlug}`
    )
  }
  getEventGalleries(eventSlug: string) {
    return this.http.get<any>(
      `${environment.host}/events/${eventSlug}/galleries`
    )
  }

  getEventTicket(eventSlug: string) {
    return this.authService.token.pipe(
      take(1),
      switchMap(token => {
        const httpOptions = {
          headers: new HttpHeaders({
            'Authorization': `Bearer ${token}`
          })
        };

        return this.http.get<any>(
          `${environment.host}/events/${eventSlug}/tickets`,
          httpOptions
        );
      })
    );
  }

  
  pubDeliveryOption(data: any) { this.sbj.next(data); }
  obvDeliveryOption(): Subject<any> { return this.sbj; }

  getEventFilter(page: any, keyword:string, categoryId: any, isonline: string , period: string ) {
    return this.http.get<any>(
      `${environment.host}/events?search=${keyword}&category=${categoryId}&is_online=${isonline}&period=${period}&page=${page}`
    );
  }

  getEventByCategory(categoryId: number, limit:string) {
    return this.http.get<any>(
      `${environment.host}/events?category=${categoryId}&limit=${limit}`
    );
  }

  userNeedTicket(eventSlug){

    return this.authService.token.pipe(
      take(1),
      switchMap(token => {
        const httpOptions = {
          headers: new HttpHeaders({
            'Authorization': `Bearer ${token}`
          })
        };

        return this.http.get<any>(
          `${environment.host}/events/${eventSlug}/entry`,
          httpOptions
        );
      })
    );

  }

  checkTicket(eventSlug, code){

    return this.authService.token.pipe(
      take(1),
      switchMap(token => {
        const httpOptions = {
          headers: new HttpHeaders({
            // 'Authorization': `Bearer ${token}`
          })
        };

        let body = new FormData();
        body.append('code',code);

        return this.http.post<any>(
          `${environment.host}/events/${eventSlug}/code`,body,
          httpOptions
        );
      })
    );

  }

  userSubmitTicket(eventSlug, code){

    return this.authService.token.pipe(
      take(1),
      switchMap(token => {

        let body = new FormData();
        body.append('code',code);

        const httpOptions = {
          headers: new HttpHeaders({
            'Authorization': `Bearer ${token}`
          })
        };

        return this.http.post<any>(
          `${environment.host}/events/${eventSlug}/entry`, body,
          httpOptions
        );
      })
    );

  }

  getEventSurvey(eventSlug){

    return this.authService.token.pipe(
      take(1),
      switchMap(token => {

        const httpOptions = {
          headers: new HttpHeaders({
            'Authorization': `Bearer ${token}`,
            'X-Localization': this.language
          })
        };

        return this.http.get<any>(
          `${environment.host}/events/${eventSlug}/surveys`,
          httpOptions
        );
      })
    );

  }

  submitEventSurvey(eventSlug,surveyID, answer){

    return this.authService.token.pipe(
      take(1),
      switchMap(token => {

        let body = {
          "survey_id": surveyID,
          "answers": answer
        };
        

        const httpOptions = {
          headers: new HttpHeaders({
            'Authorization': `Bearer ${token}`,
            'Accept': 'application/json',
            'X-Localization': this.language
          })
        };

        return this.http.post<any>(
          `${environment.host}/events/${eventSlug}/surveys`,body,
          httpOptions
        );
      })
    );

  }

  getEventExhibitor(eventSlug){

    return this.authService.token.pipe(
      take(1),
      switchMap(token => {

        const httpOptions = {
          headers: new HttpHeaders({
            'Authorization': `Bearer ${token}`,
            'X-Localization': this.language
          })
        };

        return this.http.get<any>(
          `${environment.host}/events/${eventSlug}/exhibitors`,
          httpOptions
        );
      })
    );

  }

  getEventExhibitorDetail(eventSlug, exhibitorID){

    return this.authService.token.pipe(
      take(1),
      switchMap(token => {

        const httpOptions = {
          headers: new HttpHeaders({
            'Authorization': `Bearer ${token}`,
            'X-Localization': this.language
          })
        };

        return this.http.get<any>(
          `${environment.host}/events/${eventSlug}/exhibitors/${exhibitorID}`,
          httpOptions
        );
      })
    );

  }

  getEventSponsor(eventSlug){

    return this.authService.token.pipe(
      take(1),
      switchMap(token => {

        const httpOptions = {
          headers: new HttpHeaders({
            'Authorization': `Bearer ${token}`,
            'X-Localization': this.language
          })
        };

        return this.http.get<any>(
          `${environment.host}/events/${eventSlug}/sponsors`,
          httpOptions
        );
      })
    );

  }

  getEventParticipant(eventSlug, keyword, limit, page){

    return this.authService.token.pipe(
      take(1),
      switchMap(token => {

        const httpOptions = {
          headers: new HttpHeaders({
            'Authorization': `Bearer ${token}`,
            'X-Localization': this.language
          })
        };

        return this.http.get<any>(
          `${environment.host}/events/${eventSlug}/participants?keyword=${keyword}&limit=${limit}&page=${page}`,
          httpOptions
        );
      })
    );

  }

  getEventParticipantCount(eventSlug){

    return this.authService.token.pipe(
      take(1),
      switchMap(token => {

        const httpOptions = {
          headers: new HttpHeaders({
            'Authorization': `Bearer ${token}`,
            'X-Localization': this.language
          })
        };

        return this.http.get<any>(
          `${environment.host}/events/${eventSlug}/participantsCount`,
          httpOptions
        );
      })
    );

  }

  getEventFAQ(eventSlug){

    return this.authService.token.pipe(
      take(1),
      switchMap(token => {

        const httpOptions = {
          headers: new HttpHeaders({
            'Authorization': `Bearer ${token}`,
            'X-Localization': this.language
          })
        };

        return this.http.get<any>(
          `${environment.host}/events/${eventSlug}/faq`,
          httpOptions
        );
      })
    );

  }

  getEventSpeaker(eventSlug){

    return this.authService.token.pipe(
      take(1),
      switchMap(token => {

        const httpOptions = {
          headers: new HttpHeaders({
            'Authorization': `Bearer ${token}`,
            'X-Localization': this.language
          })
        };

        return this.http.get<any>(
          `${environment.host}/events/${eventSlug}/speakers`,
          httpOptions
        );
      })
    );

  }

  getEventSession(eventSlug){

    return this.authService.token.pipe(
      take(1),
      switchMap(token => {

        const httpOptions = {
          headers: new HttpHeaders({
            'Authorization': `Bearer ${token}`,
            'X-Localization': this.language
          })
        };

        return this.http.get<any>(
          `${environment.host}/events/${eventSlug}/sessions`,
          httpOptions
        );
      })
    );

  }

  getEventSchedule(eventSlug){

    const httpOptions = {
      headers: new HttpHeaders({
        'X-Localization': this.language
      })
    };

    return this.http.get<any>(
      `${environment.host}/events/${eventSlug}/schedules`,
      httpOptions
    );

  }

  getEventSessionDetail(eventSlug, sesionID){

    return this.authService.token.pipe(
      take(1),
      switchMap(token => {

        const httpOptions = {
          headers: new HttpHeaders({
            'Authorization': `Bearer ${token}`,
            'X-Localization': this.language
          })
        };

        return this.http.get<any>(
          `${environment.host}/events/${eventSlug}/sessions/${sesionID}`,
          httpOptions
        );
      })
    );

  }

  getEventSessionStream(eventSlug, sesionID){

    return this.authService.token.pipe(
      take(1),
      switchMap(token => {

        const httpOptions = {
          headers: new HttpHeaders({
            'Authorization': `Bearer ${token}`,
            'X-Localization': this.language
          })
        };

        return this.http.get<any>(
          `${environment.host}/events/${eventSlug}/sessions/${sesionID}/stream`,
          httpOptions
        );
      })
    );

  }

  getEventSessionSurvey(eventSlug, sesionID){

    return this.authService.token.pipe(
      take(1),
      switchMap(token => {

        const httpOptions = {
          headers: new HttpHeaders({
            'Authorization': `Bearer ${token}`,
            'X-Localization': this.language
          })
        };

        return this.http.get<any>(
          `${environment.host}/events/${eventSlug}/sessions/${sesionID}/survey`,
          httpOptions
        );
      })
    );

  }

  getEventRoom(eventSlug){

    return this.authService.token.pipe(
      take(1),
      switchMap(token => {

        const httpOptions = {
          headers: new HttpHeaders({
            'Authorization': `Bearer ${token}`,
            'X-Localization': this.language
          })
        };

        return this.http.get<any>(
          `${environment.host}/events/${eventSlug}/rooms`,
          httpOptions
        );
      })
    );

  }

  getEventMatchInterest(eventSlug){

    return this.authService.token.pipe(
      take(1),
      switchMap(token => {

        const httpOptions = {
          headers: new HttpHeaders({
            'Authorization': `Bearer ${token}`,
            'X-Localization': this.language
          })
        };

        return this.http.get<any>(
          `${environment.host}/events/${eventSlug}/matchInterests`,
          httpOptions
        );
      })
    );

  }

  createEventTimeline(eventID, body, files){

    return this.authService.token.pipe(
      take(1),
      switchMap(token => {

        let form = new FormData();
        form.append('event_id',eventID);
        form.append('body',body);
        

        if(files){

          let fileArray = []
          fileArray.push(files)

          form.append('files[0]',files);
          
        }

        const httpOptions = {
          headers: new HttpHeaders({
            'Authorization': `Bearer ${token}`,
            'Accept': 'application/json',
            'X-Localization': this.language
          })
        };

        return this.http.post<any>(
          `${environment.host}/posts`,form,
          httpOptions
        );
      })
    );

  }

  getEventTimeline(eventID){

    return this.authService.token.pipe(
      take(1),
      switchMap(token => {

        const httpOptions = {
          headers: new HttpHeaders({
            'Authorization': `Bearer ${token}`,
            'Accept': 'application/json',
            'X-Localization': this.language
          })
        };

        return this.http.get<any>(
          `${environment.host}/posts?event_id=${eventID}`,
          httpOptions
        );
      })
    );

  }

  getEventTimelineCommnet(postID){

    return this.authService.token.pipe(
      take(1),
      switchMap(token => {

        const httpOptions = {
          headers: new HttpHeaders({
            'Authorization': `Bearer ${token}`,
            'Accept': 'application/json',
            'X-Localization': this.language
          })
        };

        return this.http.get<any>(
          `${environment.host}/posts/${postID}/comments`,
          httpOptions
        );
      })
    );

  }

  submitEventTimelineCommnet(postID, comment){

    return this.authService.token.pipe(
      take(1),
      switchMap(token => {

        const httpOptions = {
          headers: new HttpHeaders({
            'Authorization': `Bearer ${token}`,
            'Accept': 'application/json',
            'X-Localization': this.language
          })
        };

        let body = {
          "body" : comment
        }

        return this.http.post<any>(
          `${environment.host}/posts/${postID}/comments`, body,
          httpOptions
        );
      })
    );

  }

  getEventTimelineCommnetReply(postID, commentID){

    return this.authService.token.pipe(
      take(1),
      switchMap(token => {

        const httpOptions = {
          headers: new HttpHeaders({
            'Authorization': `Bearer ${token}`,
            'Accept': 'application/json',
            'X-Localization': this.language
          })
        };

        return this.http.get<any>(
          `${environment.host}/posts/${postID}/comments/${commentID}/replies`,
          httpOptions
        );
      })
    );

  }

  submitEventTimelineCommnetReply(postID, commentID, reply){

    return this.authService.token.pipe(
      take(1),
      switchMap(token => {

        const httpOptions = {
          headers: new HttpHeaders({
            'Authorization': `Bearer ${token}`,
            'Accept': 'application/json',
            'X-Localization': this.language
          })
        };

        let body = {
          "body" : reply
        }

        return this.http.post<any>(
          `${environment.host}/posts/${postID}/comments/${commentID}/replies`, body,
          httpOptions
        );
      })
    );

  }

  getEventForum(eventID){

    return this.authService.token.pipe(
      take(1),
      switchMap(token => {

        const httpOptions = {
          headers: new HttpHeaders({
            'Authorization': `Bearer ${token}`,
            'Accept': 'application/json',
            'X-Localization': this.language
          })
        };

        return this.http.get<any>(
          `${environment.host}/forums?event_id=${eventID}`,
          httpOptions
        );
      })
    );

  }

  getEventForumThread(forumID){

    return this.authService.token.pipe(
      take(1),
      switchMap(token => {

        const httpOptions = {
          headers: new HttpHeaders({
            'Authorization': `Bearer ${token}`,
            'Accept': 'application/json',
            'X-Localization': this.language
          })
        };

        return this.http.get<any>(
          `${environment.host}/forums/${forumID}/threads`,
          httpOptions
        );
      })
    );

  }

  postEventForumThread(forumID, comment){

    return this.authService.token.pipe(
      take(1),
      switchMap(token => {

        const httpOptions = {
          headers: new HttpHeaders({
            'Authorization': `Bearer ${token}`,
            'Accept': 'application/json',
            'X-Localization': this.language
          })
        };

        let body = {
          "body" : comment
        }

        return this.http.post<any>(
          `${environment.host}/forums/${forumID}/threads`,
          body,
          httpOptions
        );
      })
    );

  }

  getEventForumThreadComment(forumID,threadID){

    return this.authService.token.pipe(
      take(1),
      switchMap(token => {

        const httpOptions = {
          headers: new HttpHeaders({
            'Authorization': `Bearer ${token}`,
            'Accept': 'application/json',
            'X-Localization': this.language
          })
        };

        return this.http.get<any>(
          `${environment.host}/forums/${forumID}/threads/${threadID}/replies`,
          httpOptions
        );
      })
    );

  }

  replyEventForumThread(forumID,threadID, comment){

    return this.authService.token.pipe(
      take(1),
      switchMap(token => {

        const httpOptions = {
          headers: new HttpHeaders({
            'Authorization': `Bearer ${token}`,
            'Accept': 'application/json',
            'X-Localization': this.language
          })
        };

        let body = {
          "body" : comment
        }

        return this.http.post<any>(
          `${environment.host}/forums/${forumID}/threads/${threadID}/replies`,
          body,
          httpOptions
        );
      })
    );

  }

  getEventAward(eventslug){

    return this.authService.token.pipe(
      take(1),
      switchMap(token => {

        const httpOptions = {
          headers: new HttpHeaders({
            'Authorization': `Bearer ${token}`,
            'Accept': 'application/json',
            'X-Localization': this.language
          })
        };

        return this.http.get<any>(
          `${environment.host}/events/${eventslug}/awards`,
          httpOptions
        );
      })
    );

  }

  getAuctionList(eventslug){

    return this.authService.token.pipe(
      take(1),
      switchMap(token => {

        const httpOptions = {
          headers: new HttpHeaders({
            'Authorization': `Bearer ${token}`,
            'Accept': 'application/json',
            'X-Localization': this.language
          })
        };

        return this.http.get<any>(
          `${environment.host}/events/${eventslug}/auctions`,
          httpOptions
        );
      })
    );

  }

  getAuctionDetail(eventslug, auctionID){

    return this.authService.token.pipe(
      take(1),
      switchMap(token => {

        const httpOptions = {
          headers: new HttpHeaders({
            'Authorization': `Bearer ${token}`,
            'Accept': 'application/json',
            'X-Localization': this.language
          })
        };

        return this.http.get<any>(
          `${environment.host}/events/${eventslug}/auctions/${auctionID}`,
          httpOptions
        );
      })
    );

  }
  
  submitBid(eventslug, auctionID){

    return this.authService.token.pipe(
      take(1),
      switchMap(token => {

        const httpOptions = {
          headers: new HttpHeaders({
            'Authorization': `Bearer ${token}`,
            'Accept': 'application/json',
            'X-Localization': this.language
          })
        };

        let body = {
          
        }

        return this.http.post<any>(
          `${environment.host}/events/${eventslug}/auctions/${auctionID}/bid`,body,
          httpOptions
        );
      })
    );

  }

  getMyPoint(eventslug){

    return this.authService.token.pipe(
      switchMap(token => {
        const httpOptions = {
          headers: new HttpHeaders({
            'Authorization': `Bearer ${token}`,
            'Accept': 'application/json',
            'X-Localization': `${this.language}`
          })
        };
        return this.http.get<any>(
          `${environment.host}/events/${eventslug}/points/me`,
          httpOptions
        );
      })
    );

  }
   
  getHowToPlay(eventslug){

    return this.authService.token.pipe(
      switchMap(token => {
        const httpOptions = {
          headers: new HttpHeaders({
            'Authorization': `Bearer ${token}`,
            'Accept': 'application/json',
            'X-Localization': `${this.language}`
          })
        };
        return this.http.get<any>(
          `${environment.host}/events/${eventslug}/howTo`,
          httpOptions
        );
      })
    );

  }

  getLeaderboard(eventslug){

    return this.authService.token.pipe(
      switchMap(token => {
        const httpOptions = {
          headers: new HttpHeaders({
            'Authorization': `Bearer ${token}`,
            'Accept': 'application/json',
            'X-Localization': `${this.language}`
          })
        };
        return this.http.get<any>(
          `${environment.host}/events/${eventslug}/points/rankings`,
          httpOptions
        );
      })
    );

  }

  getHistoryPoint(eventslug, page){

    return this.authService.token.pipe(
      switchMap(token => {
        const httpOptions = {
          headers: new HttpHeaders({
            'Authorization': `Bearer ${token}`,
            'Accept': 'application/json',
            'X-Localization': `${this.language}`
          })
        };
        return this.http.get<any>(
          `${environment.host}/events/${eventslug}/points/history?page=${page}`,
          httpOptions
        );
      })
    );

  }

  getEventBadges(eventslug){

    return this.authService.token.pipe(
      switchMap(token => {
        const httpOptions = {
          headers: new HttpHeaders({
            'Authorization': `Bearer ${token}`,
            'Accept': 'application/json',
            'X-Localization': `${this.language}`
          })
        };
        return this.http.get<any>(
          `${environment.host}/events/${eventslug}/badges`,
          httpOptions
        );
      })
    );

  }

  getEventMissionBoard(eventslug){

    return this.authService.token.pipe(
      switchMap(token => {
        const httpOptions = {
          headers: new HttpHeaders({
            'Authorization': `Bearer ${token}`,
            'Accept': 'application/json',
            'X-Localization': `${this.language}`
          })
        };
        return this.http.get<any>(
          `${environment.host}/events/${eventslug}/missionBoards`,
          httpOptions
        );
      })
    );

  }

  getEventMissionBoardDetail(eventslug, missionID){

    return this.authService.token.pipe(
      switchMap(token => {
        const httpOptions = {
          headers: new HttpHeaders({
            'Authorization': `Bearer ${token}`,
            'Accept': 'application/json',
            'X-Localization': `${this.language}`
          })
        };
        return this.http.get<any>(
          `${environment.host}/events/${eventslug}/missionBoards/${missionID}`,
          httpOptions
        );
      })
    );

  }

  getRedeem(eventslug){

    return this.authService.token.pipe(
      switchMap(token => {
        const httpOptions = {
          headers: new HttpHeaders({
            'Authorization': `Bearer ${token}`,
            'Accept': 'application/json',
            'X-Localization': `${this.language}`
          })
        };
        return this.http.get<any>(
          `${environment.host}/events/${eventslug}/gifts`,
          httpOptions
        );
      })
    );

  }

  submitRedeem(eventslug, itemID, phone, name){

    return this.authService.token.pipe(
      switchMap(token => {
        const httpOptions = {
          headers: new HttpHeaders({
            'Authorization': `Bearer ${token}`,
            'Accept': 'application/json',
            'X-Localization': `${this.language}`
          })
        };

        let body = {
          "phone" : phone,
          "name" : name
        }

        return this.http.post<any>(
          `${environment.host}/events/${eventslug}/gifts/${itemID}/redeem`,
          body,
          httpOptions
        );
      })
    );

  }

  getEventMaterialList(eventslug, keyword){

    return this.authService.token.pipe(
      take(1),
      switchMap(token => {

        const httpOptions = {
          headers: new HttpHeaders({
            'Authorization': `Bearer ${token}`,
            'Accept': 'application/json',
            'X-Localization': this.language
          })
        };

        return this.http.get<any>(
          `${environment.host}/events/${eventslug}/materials?keyword=`+keyword,
          httpOptions
        );
      })
    );

  }

  getEventBrochureList(eventslug, keyword){

    return this.authService.token.pipe(
      take(1),
      switchMap(token => {

        const httpOptions = {
          headers: new HttpHeaders({
            'Authorization': `Bearer ${token}`,
            'Accept': 'application/json',
            'X-Localization': this.language
          })
        };

        return this.http.get<any>(
          `${environment.host}/events/${eventslug}/brochures?keyword=`+keyword,
          httpOptions
        );
      })
    );

  }

  saveMaterial(eventslug, itemID){

    return this.authService.token.pipe(
      take(1),
      switchMap(token => {

        const httpOptions = {
          headers: new HttpHeaders({
            'Authorization': `Bearer ${token}`,
            'Accept': 'application/json',
            'X-Localization': this.language
          })
        };

        return this.http.post<any>(
          `${environment.host}/events/${eventslug}/materials/${itemID}/saved`,null,
          httpOptions
        );
      })
    );

  }

  getEventMerchandiseList(eventslug){

    return this.authService.token.pipe(
      take(1),
      switchMap(token => {

        const httpOptions = {
          headers: new HttpHeaders({
            'Authorization': `Bearer ${token}`,
            'Accept': 'application/json',
            'X-Localization': this.language
          })
        };

        return this.http.get<any>(
          `${environment.host}/events/${eventslug}/merchandise`,
          httpOptions
        );
      })
    );

  }

  getEventMerchandiseDetail(eventslug, itemID){

    return this.authService.token.pipe(
      take(1),
      switchMap(token => {

        const httpOptions = {
          headers: new HttpHeaders({
            'Authorization': `Bearer ${token}`,
            'Accept': 'application/json',
            'X-Localization': this.language
          })
        };

        return this.http.get<any>(
          `${environment.host}/events/${eventslug}/merchandise/${itemID}`,
          httpOptions
        );
      })
    );

  }

  getEventCountdown(eventSlug: string) {
    return this.http.get<any>(
      `${environment.host}/events/${eventSlug}/countdown`
    )
  }

  getEventSessionSchedule(eventID:string){

    return this.authService.token.pipe(
      take(1),
      switchMap(token => {

        const httpOptions = {
          headers: new HttpHeaders({
            'Authorization': `Bearer ${token}`,
            'Accept': 'application/json',
            'X-Localization': this.language
          })
        };

        return this.http.get<any>(
          `${environment.host}/events/${eventID}/sessionSchedules`,
          httpOptions
        );
      })
    );

  }

  getExhibitionHall(eventID:string){

    return this.authService.token.pipe(
      take(1),
      switchMap(token => {

        const httpOptions = {
          headers: new HttpHeaders({
            'Authorization': `Bearer ${token}`,
            'Accept': 'application/json',
            'X-Localization': this.language
          })
        };

        return this.http.get<any>(
          `${environment.host}/events/${eventID}/halls`,
          httpOptions
        );
      })
    );

  }

  getExhibitionHallDetail(eventID:string, hallID:string){

    return this.authService.token.pipe(
      take(1),
      switchMap(token => {

        const httpOptions = {
          headers: new HttpHeaders({
            'Authorization': `Bearer ${token}`,
            'Accept': 'application/json',
            'X-Localization': this.language
          })
        };

        return this.http.get<any>(
          `${environment.host}/events/${eventID}/halls/${hallID}`,
          httpOptions
        );
      })
    );

  }

  getExhibitorOperational(eventID, exhibitorID){

    return this.authService.token.pipe(
      take(1),
      switchMap(token => {

        const httpOptions = {
          headers: new HttpHeaders({
            'Authorization': `Bearer ${token}`,
            'Accept': 'application/json',
            'X-Localization': this.language
          })
        };

        return this.http.get<any>(
          `${environment.host}/events/${eventID}/exhibitors/${exhibitorID}/operationalTime`,
          httpOptions
        );
      })
    );

  }

  getExhibitorScheduleMeeting(eventID, exhibitorID){
    return this.authService.token.pipe(
      take(1),
      switchMap(token => {

        const httpOptions = {
          headers: new HttpHeaders({
            'Authorization': `Bearer ${token}`,
            'Accept': 'application/json',
            'X-Localization': this.language
          })
        };

        return this.http.get<any>(
          `${environment.host}/events/${eventID}/exhibitors/${exhibitorID}/meetingSchedules`,
          httpOptions
        );
      })
    );
  }

  getUpgradeTicektList(eventID){

    return this.authService.token.pipe(
      take(1),
      switchMap(token => {

        const httpOptions = {
          headers: new HttpHeaders({
            'Authorization': `Bearer ${token}`,
            'Accept': 'application/json',
            'X-Localization': this.language
          })
        };

        return this.http.get<any>(
          `${environment.host}/events/${eventID}/upgradableTickets`,
          httpOptions
        );
      })
    );

  }

  getDownloadFile(url){

    return this.authService.token.pipe(
      take(1),
      switchMap(token => {        

        const httpOptions = {
          headers: new HttpHeaders({
            'Authorization': `Bearer ${token}`,
            'Accept': 'application/json'
          }),
          'responseType'  : 'arraybuffer' as 'json'
        };
        
        return this.http.get<any>(
          `${url}`,
          httpOptions
        );
      })
    );
  }

  uploadPhotobooth(eventID,image){

    return this.authService.token.pipe(
      take(1),
      switchMap(token => {

        const httpOptions = {
          headers: new HttpHeaders({
            'Authorization': `Bearer ${token}`,
            'Accept': 'application/json',
            'X-Localization': this.language
          })
        };

        let body = new FormData();
        body.append('base64',image);

        return this.http.post<any>(
          `${environment.host}/events/${eventID}/photoBooth`,body,
          httpOptions
        );
      })
    );

  }

  getScavangerSchedule(eventID, page){

    return this.authService.token.pipe(
      take(1),
      switchMap(token => {

        const httpOptions = {
          headers: new HttpHeaders({
            'Authorization': `Bearer ${token}`,
            'Accept': 'application/json',
            'X-Localization': this.language
          })
        };

        return this.http.get<any>(
          `${environment.host}/events/${eventID}/scavengerHunts?page=${page}`,
          httpOptions
        );
      })
    );

  }

  submitScavanger(eventID,url){

    return this.authService.token.pipe(
      take(1),
      switchMap(token => {

        const httpOptions = {
          headers: new HttpHeaders({
            'Authorization': `Bearer ${token}`,
            'Accept': 'application/json',
            'X-Localization': this.language
          })
        };

        return this.http.post<any>(
          url,null,
          httpOptions
        );
      })
    );

  }

  submitMiniGames(eventID,name){

    return this.authService.token.pipe(
      take(1),
      switchMap(token => {

        const httpOptions = {
          headers: new HttpHeaders({
            'Authorization': `Bearer ${token}`,
            'Accept': 'application/json',
            'X-Localization': this.language
          })
        };

        return this.http.get<any>(
          `${environment.host}/events/${eventID}/triggerPlayGamePoints?name=${name}`,
          httpOptions
        );
      })
    );

  }

  getMaterial(eventID, keyword){
    // console.log(eventID)
    return this.authService.token.pipe(
      take(1),
      switchMap(token => {

        const httpOptions = {
          headers: new HttpHeaders({
            'Authorization': `Bearer ${token}`,
            'Accept': 'application/json',
            'X-Localization': this.language
          })
        };

        return this.http.get<any>(
          `${environment.host}/events/${eventID}/materials?search=${keyword}`,
          httpOptions
        );
      })
    );

  }

  getOngoingSession(eventID){
    // console.log(eventID)
    return this.authService.token.pipe(
      take(1),
      switchMap(token => {

        const httpOptions = {
          headers: new HttpHeaders({
            'Authorization': `Bearer ${token}`,
            'Accept': 'application/json',
            'X-Localization': this.language
          })
        };

        return this.http.get<any>(
          `${environment.host}/events/${eventID}/sessionsOngoing`,
          httpOptions
        );
      })
    );

  }

  validasiPlayback(eventID){

    return this.authService.token.pipe(
      take(1),
      switchMap(token => {

        const httpOptions = {
          headers: new HttpHeaders({
            'Authorization': `Bearer ${token}`,
            'Accept': 'application/json',
            'X-Localization': this.language
          })
        };

        return this.http.get<any>(
          `${environment.host}/events/${eventID}/validateVideoPlayback`,
          httpOptions
        );
      })
    );

  }

  getPlaybackVideo(eventID, sessionID){

    return this.authService.token.pipe(
      take(1),
      switchMap(token => {

        const httpOptions = {
          headers: new HttpHeaders({
            'Authorization': `Bearer ${token}`,
            'Accept': 'application/json',
            'X-Localization': this.language
          })
        };

        return this.http.get<any>(
          `${environment.host}/events/${eventID}/sessions/${sessionID}/playback`,
          httpOptions
        );
      })
    );

  }

  setNotifFlag(){

    this.notifFlag.next(1)

  }

  getNotifFlagObserver(){

    return this.notifFlag

  }

  setFlagMessage(){

    this.notifMessage.next(1)

  }

  getFlagMessageObserver(){

    return this.notifMessage

  }


}

import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { tap, map, take, switchMap } from 'rxjs/operators';
import { BehaviorSubject, from } from 'rxjs';
import { User } from '../models/user.model';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { LanguageService } from './language.service';
import { Token } from '../models/token.model';
import { Storage } from '@capacitor/storage';

//firebase
import firebase from 'firebase/app'
import 'firebase/database';
import { NavController } from '@ionic/angular';
export const myFirebaseRedirect = firebase.initializeApp(environment.firebase, 'redirectFirebase');
export const chatRoomDefault = '/kelolain/multiple_login/';
//firebase
declare var leave: Function;

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private _user = new BehaviorSubject<User>(null);
  private _token = new BehaviorSubject<Token>(null);
  private _fcm = new BehaviorSubject<String>(null);
  language;
  private myFirebaseListener;

  constructor(
    private http: HttpClient,
    private languageService: LanguageService,
    private navCtrl: NavController
  )
  {
    this.language = this.languageService.language();
  }

  get userIsAuthenticated() {

    return from(Storage.get({key: 'tokenData'})).pipe(
      map(tokenData => {
        if(!tokenData || !tokenData.value){
          return false;
        }else{
          let token = new Token().deserialize(JSON.parse(tokenData.value))

          return !token.isTokenExpired()

        }
      })
    );

  }

  get token() {

    return from(Storage.get({key: 'tokenData'})).pipe(
      map(tokenData => {
        if(!tokenData || !tokenData.value){
          return null;
        }
        
        let token = new Token().deserialize(JSON.parse(tokenData.value))

        return token.getAccessToken();
      })
    );

    // return this._token.asObservable().pipe(
    //   map(token => {
    //     if(token) {
    //       return token.access_token;
    //     } else {
    //       return null;
    //     }
    //   })
    // );
  }

  get user(){
    // var dataUser =  this._user.value;
    // if(dataUser){
    //   localStorage.dataGlobal =  JSON.stringify(dataUser);
    // }
    // return this._user.asObservable();

    return from(Storage.get({key: 'authData'})).pipe(
      map(authData => {
        if(!authData || !authData.value){
          return null;
        }

        return JSON.parse(authData.value);
      })
    );

  }

  loginCode(code){
    let body = new FormData();
    body.append('code',code);

    return this.http.post<any>(
      `${environment.host}/auth/invitation`,
      body
    )
    .pipe(
      map(resData => {
        return resData;
      }),
      // tap(this.setUserData.bind(this))
    );
  }

  loginEmail(email){
    let body = new FormData();
    body.append('email',email);

    return this.http.post<any>(
      `${environment.host}/auth/email`,
      body
    )
    .pipe(
      map(resData => {
        return resData;
      }),
      // tap(this.setUserData.bind(this))
    );
  }

  // login(email: string, password: string, fcm_token: any = '', uuid: string, os: string, os_version: string, model: string){
  //   let body = new FormData();
  //   body.append('email',email);
  //   body.append('password',password);
  //   if(fcm_token != '') {
  //     body.append('fcm_token',fcm_token);
  //   }
  //   body.append('uuid',uuid);
  //   body.append('os',os);
  //   body.append('os_version',os_version);
  //   body.append('model',model);
  //   body.append('language',this.language);

  //   return this.http.post<any>(
  //     `${environment.host}/auth/login`,
  //     body
  //   )
  //   .pipe(
  //     map(resData => {
  //       return resData;
  //     }),
  //     // tap(this.setUserData.bind(this))
  //   );
  // }

  loginIdentifier(identifier: string){
    let body = new FormData();
    body.append('identifier',identifier);

    return this.http.post<any>(
      `${environment.host}/auth/login`,
      body
    )
    .pipe(
      map(resData => {
        return resData;
      }),
      // tap(this.setUserData.bind(this))
    );
  }

  loginGoogle(token: string, fcm_token: any = '',  uuid: string, os: string, os_version: string, model: string){
    let body = new FormData();
    body.append('token',token);
    if(fcm_token != '') {
      body.append('fcm_token',fcm_token);
    }
    body.append('uuid',uuid);
    body.append('os',os);
    body.append('os_version',os_version);
    body.append('model',model);
    body.append('language',this.language);

    return this.http.post<any>(
      `${environment.host}/auth/login/google`,
      body
    )
    .pipe(
      map(resData => {
        return resData;
      }),
      //tap(this.setUserData.bind(this))
    );
  }

  // appleLogin(email: string, fullname: string,  apple_id: string, hash_code: string, fcm_token: any = '',  uuid: string, os: string, os_version: string, model: string) {
  //   let body = new FormData();
  //   if(email != '' && email != 'null' && email != null) {
  //     body.append('member_email',email);
  //   }
  //   if(email != '' && email != 'null' && email != null) {
  //     body.append('member_fullname',fullname);
  //   }
  //   body.append('apple_id',apple_id);
  //   body.append('hash_code',hash_code);
  //   if(fcm_token != '') {
  //     body.append('fcm_token',fcm_token);
  //   }
  //   body.append('uuid',uuid);
  //   body.append('os',os);
  //   body.append('os_version',os_version);
  //   body.append('model',model);
  //   body.append('language',this.language);

  //   return this.http.post<any>(
  //     `${environment.baseUrl}/api/applesignin`,
  //     body
  //   )
  //   .pipe(
  //     map(resData => {
  //       return resData;
  //     }),
  //     //tap(this.setUserData.bind(this))
  //   );
  // }

  updateUserData(data:any){

    const user = new User().deserialize(data);

    this._user.next(user);

    const authData = JSON.stringify({
      id: data.id,
      name: data.name,
      email: data.email,
      phone: data.phone,
      company_name: data.company_name,
      company_position: data.company_position,
      photo_url: data.photo_url,
      interests: data.interests,
      agency: data.agency
    });

    Storage.set({key: 'authData', value: authData});

  }

  setUserData(data: any) {
    const user = new User().deserialize(data);

    this._user.next(user);

    const authData = JSON.stringify({
      id: data.id,
      name: data.name,
      email: data.email,
      phone: data.phone,
      company_name: data.company_name,
      company_position: data.company_position,
      photo_url: data.photo_url,
      interests: data.interests,
      agency: data.agency
    });

    Storage.set({key: 'authData', value: authData});

    Storage.get({ key: 'tokenData' }).then((val) => {
      this.callForceRedirectFunction(val, user);
    });

  }

  autoLogin() {
    return from(Storage.get({key: 'authData'})).pipe(
      map(storedData => {
        if(!storedData || !storedData.value){
          return null;
        }
        const parsedData = JSON.parse(storedData.value) as {
          id: string,
          name: string,
          email: string,
          phone: string,
          company_name: string,
          company_position: string,
          photo_url: string,
          interests: string
        };

        const user = new User().deserialize(parsedData);
        return user;
      }),
      tap(user => {
        if(user){
          this._user.next(user);
        }
      }),
      map(user => {
        return !!user;
      })
    );
  }

  get fcm(){
    return this._fcm.asObservable();
  }

  setFcmToken(token){
    this._fcm.next(token);
  }

  setUserToken(data: any) {

    const authData = JSON.stringify({
      access_token: data.access_token,
      token_type: data.token_type,
      expires_in: data.expires_in,
      setDate: Math.floor(Date.now() / 1000)
    });

    Storage.set({key: 'tokenData', value: authData});
    
  }

  callForceRedirectFunction(dataToken, user:User) {
      // console.log(dataToken)
       let token = new Token().deserialize(JSON.parse(dataToken.value))
      //  console.log("0")
      //  console.log(token.getAccessToken())
       if (token.getAccessToken()) {
        // console.log("1")
        // console.log(chatRoomDefault + user.getID())
         this.myFirebaseListener = myFirebaseRedirect.database().ref(chatRoomDefault + user.getID());
         this.myFirebaseListener.on('value', (snapshot) => {
            // console.log(snapshot.val()) 

            let myValue = snapshot.val();
             if(myValue){
               if (token.getAccessToken() == myValue.access_token) {
                  //  console.log('Token sama');
                 if (this.myFirebaseListener) {
                   this.myFirebaseListener.off();
                 }
                 this._user.next(null);
                 this.clearAuthData()
                 leave()
                //  this.navCtrl.navigateRoot('/home?logout=true');
                 this.navCtrl.navigateRoot('/dual-login');
               } else {
                 //  console.log('Token Sama');
               }
             }
         });
       }
  }

  refreshToken(){

    return this.token.pipe(
      take(1),
      switchMap(token => {
        
        let body = new FormData();

        const httpOptions = {
          headers: new HttpHeaders({
            'Authorization': `Bearer ${token}`
          })
        };

        return this.http.post<any>(
          `${environment.host}/auth/refresh`, body ,
          httpOptions
        );
      })
    );

  }


  registerMember(code, form, capchatoken, type){

    let phone = ''

    if(form.phone.charAt(0) == 0){
      phone = "62" + form.phone.substring(1)
    }else{
      phone = "62" + form.phone
    }

    let body = {};

    if(type == "personal"){

      body = {
        "code": code,
        "name": form.name,
        "email": form.email,
        "phone": phone,
        "participant_type": type,
        "token": capchatoken
      }

    }else if(type == "institution"){

      body = {
        "code": code,
        "name": form.name,
        "email": form.email,
        "phone": phone,
        "participant_type": type,
        "agency": form.company,
        "token": capchatoken
      }

    }
    

    const httpOptions = {
      headers: new HttpHeaders({
        'Accept': 'application/json',
        'X-Agent': 'android'
      })
    };
    
    return this.http.post<any>(
      `${environment.host}/auth/register`,
      body,
      httpOptions
    );

  }

  verifyRegisterForm(activation_token: string, activation_code: string) {
    // let body = new FormData();
    // body.append('token',activation_code);

    const body = { token: activation_code };

    const httpOptions = {
      headers: new HttpHeaders({
        
      })
    };

    return this.http.put<any>(
      `${environment.host}/auth/register/${activation_token}`,
      body, httpOptions
    );

    
  }

  resendVerifyRegisterFormtOtp(activation_token: string) {
    let body = new FormData();
    // body.append('token',activation_code);

    return this.http.post<any>(
      `${environment.host}/auth/register/${activation_token}/resend`,
      body
    );
    
  }

  clearAuthData(){

    Storage.remove({key: 'authData'});
    Storage.remove({key: 'tokenData'});

  }

}

import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { HeaderComponent } from "./header/header.component";
import { HeaderEventComponent } from "./header-event/header-event.component";
import { FormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { NgbDropdownModule, NgbCollapseModule } from "@ng-bootstrap/ng-bootstrap";
import { MatExpansionModule } from '@angular/material/expansion';
import { HeaderMobileComponent } from "./header-mobile/header-mobile.component";
import { NotificationComponent } from "./notification/notification.component";
import { GiftComponent } from "./gift/gift.component";

@NgModule({
  declarations: [HeaderComponent, HeaderEventComponent, HeaderMobileComponent, NotificationComponent, GiftComponent],
  exports: [HeaderComponent, HeaderEventComponent, HeaderMobileComponent, NotificationComponent, GiftComponent],
  imports: [
    RouterModule, CommonModule,FormsModule,
		NgbDropdownModule,
		NgbCollapseModule,
    MatExpansionModule
  ],

  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ]

})
export class ComponentsModule {}

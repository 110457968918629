import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { LoadingController } from '@ionic/angular';
import { ScavengerHuntsModel } from 'src/app/models/EventModel/scavengerhunt.model';
import { EventService } from 'src/app/service/event.service';
import { MemberService } from 'src/app/service/member.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-gift',
  templateUrl: './gift.component.html',
  styleUrls: ['./gift.component.scss'],
})
export class GiftComponent implements OnChanges {

  @Input() page:any = "";
  @Input() parentFlag:boolean = false;


  fixedHunt: ScavengerHuntsModel = new ScavengerHuntsModel().deserialize("{}")
  specialHunt: ScavengerHuntsModel = new ScavengerHuntsModel().deserialize("{}")
  huntList:ScavengerHuntsModel[] = []

  eventID:any = environment.eventID

  isOnProcess:boolean = false

  constructor(
    private eventService: EventService,
    private loadingCtrl: LoadingController,
    private memberService: MemberService
  ) { }


  ngOnChanges(changes: SimpleChanges): void {
    if(!this.isOnProcess){
      this.isOnProcess = true

      this.memberService.getMyTicketType().subscribe(
        res =>{
  
          if(res.data.name != "Ticket Exhibitor"){

            this.getScavagerSchedule(null)

          }else{

            this.isOnProcess = false

          }
  
        },
        err => {
          this.isOnProcess = false
        }
      )

    }
    
  }

  ngOnInit() {

  }

  getScavagerSchedule(loading){



    this.fixedHunt = new ScavengerHuntsModel().deserialize("{}")
    this.specialHunt = new ScavengerHuntsModel().deserialize("{}")

    this.eventService.getScavangerSchedule(this.eventID, this.page).subscribe(

      res =>{

        this.huntList = res.data.map((list:ScavengerHuntsModel) => new ScavengerHuntsModel().deserialize(list))

        this.huntList.forEach((hunt, index) => {

          if(hunt.getHuntName() == "Special Mystery Box"){

            this.specialHunt = hunt

          }

          if(hunt.getHuntName() == "Mystery Box"){

            this.fixedHunt = hunt

          }

        })

        if(loading){
          loading.dismiss()
        }

        this.isOnProcess = false

      },
      err => {

        this.isOnProcess = false

        if(loading){
          loading.dismiss()
        }
      }

    )

  }

  submitHunt(url, type){

    this.loadingCtrl.create({ keyboardClose: true })
      .then(loading => {
        loading.present();

        this.eventService.submitScavanger(this.eventID, url).subscribe(
          res => {
            
            if(type == 'special'){
              this.specialHunt = new ScavengerHuntsModel().deserialize("{}")
            }

            if(type == 'fixed'){
              this.fixedHunt = new ScavengerHuntsModel().deserialize("{}")
            }

            // this.getScavagerSchedule(loading)
            loading.dismiss()

          },
          err => {
            loading.dismiss()
          }
        )

    })

  }

}

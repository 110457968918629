import { Deserializable } from "../deserializable.model";

export class MediaModel implements Deserializable{

    private id:string
	private name:string
    private file_url:string
    private thumbnail_url:string
    private file_type:string
    private redirect_url:string
    
	deserialize(input: any) {
		Object.assign(this, input);
		return this;
	}

    getName() { return this.name }

    getThumbnailUrl() { return this.thumbnail_url }

    getFileUrl(){
        return this.file_url
    }

    getType(){
        return this.file_type
    }

    getRedirectUrl(){
        return this.redirect_url
    }

}




import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import firebase from 'firebase/app'
import 'firebase/database';
import { environment } from 'src/environments/environment';
export const myFirebaseRedirect = firebase.initializeApp(environment.firebase, 'pushNotifFirebase');
import { take, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { Plugins } from '@capacitor/core';
import { GeneralService } from 'src/app/service/general.service';
import { AuthService } from 'src/app/service/auth.service';
declare var openMess: Function;
declare var hideAllChat: Function;


@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss'],
})
export class NotificationComponent implements OnChanges {

  // @Input() changeFlag:boolean = false;
  popupNotif:any = 0;
  @Input() textTitle:any='';
  @Input() textDescription:any='';
  @Input() clickaction:any = ''
  @Input() chage:boolean = false
  code:any = ''
  // popupNotif:any = 0;
  isAuthenticated:any;
  constructor(
    private generalService: GeneralService,
  private modalController: ModalController,
    private authService: AuthService
              ) { }
  user: any = [];
  timer:any = environment.notificationTimer

  timerDelay:any

  ngOnChanges(){


  }

  ngOnInit() {



  }

  showNotif(notification){

    this.textTitle = 'Notification'
    this.textDescription = notification.title

    if(notification.click_action){
      this.clickaction = notification.click_action
    }

    if(notification.code){
      this.textDescription = "Appointment" + notification.title
      this.code = notification.code
    }

    let ionicThis = this

    this.popupNotif = 1

    this.timerDelay = setTimeout(function () {

        ionicThis.closeNotif()
        clearInterval(ionicThis.timerDelay)
        // And any other code that should run only after 5s
      }, this.timer);

  }

  authCheck() {
    this.authService.userIsAuthenticated.pipe(take(1), switchMap(auth => {
      if (!auth) {
        return this.authService.autoLogin();
      } else {
        return of(auth);
      }
    })
    ).subscribe(auth => {
      this.isAuthenticated = auth;
      if (auth) {
        this.authService.user.subscribe(user => {
          this.user = user;
        });
      }

    });
  }

  closeNotif() {
    this.clearData()
    this.popupNotif = 0;

  }

  clearData(){
    this.textTitle = ''
    this.textDescription = ''
    this.clickaction = ''
    this.code = ''
  }

  openUrl(url){
    window.open(url, '_self')
  }

}

import { Deserializable } from "../deserializable.model"

export class EventParticipant implements Deserializable {

	private email: string
	private id: string
	private photo: string
	private photo_url:string
	private name:string
	private interests:any
	private phone:any
	private about:any
	private linkedin:any;
	private instagram:any;
	private company_name:string
	private company_position:string
	private is_my_network: boolean
	private networks_count:any
	private is_matching_interest: boolean
	private agency:string
	private is_click_add:boolean = false
	private is_waiting_approved:boolean

	public constructor(
		
	) {}

	getEmail(){
		return this.email
	}

	getID(){
		return this.id
	}

	getLinkedin(){
		return this.linkedin
	}
	getInstagram(){
		return this.instagram
	}
	getName(){
		return this.name
	}
	getInterest(){
		return this.interests
	}

	getPhone(){
		return this.phone
	}

	getAbout(){
		return this.about
	}

	getPhoto(){

		if(this.photo){
			return this.photo
		}else{
			return this.photo_url
		}
		
	}

	getInitial(){

		let initial = ""
		let member_name = this.name.split(" ");
		if (member_name.length > 1) {
			initial = member_name[0].charAt(0).toUpperCase() + member_name[1].charAt(0).toUpperCase();
		} else {
			initial = member_name[0].charAt(0).toUpperCase();
		}

		return initial

	}

	getCompany(){
		return this.company_name
	}

	getPosition(){
		return this.company_position
	}

	isMynetwork(){
		return this.is_my_network
	}

	setMyNetwork(){
		this.is_my_network = true
	}

	getNetworkCount(){
		return this.networks_count;
	}
	isMatchingInterest(){
		return this.is_matching_interest
	}

	getAgency(){
		return this.agency
	}

	getIsClickAdd(){
		return this.is_click_add
	}

	setIsClikAdd(value){
		this.is_click_add = value
	}

	getIsWaitingApprove(){
		return this.is_waiting_approved
	}

	setIsWaitingApprove(value){
		this.is_waiting_approved = value
	}

	deserialize(input: any) {
		Object.assign(this, input);
		return this;
	}

}
import { Deserializable } from "../deserializable.model"
import { EventSession } from "./event.model"

export class SessionSchedule implements Deserializable {

	private date: string
    private schedules:EventSession[]

	public constructor(
		
	) {}

	getDate(){
        return this.date
    }

    getSchedule(){
        return this.schedules
    }

	deserialize(input: any) {
		Object.assign(this, input);

        if(input.schedules){
            this.schedules = input.schedules.map((list:EventSession) => new EventSession().deserialize(list))
        }else{
            this.schedules = []
        }

		return this;
	}

}